import {Create, CreateProps, SimpleForm, TextInput} from "react-admin";
import React from "react";

export const LocationCreate = (props: CreateProps) => {

    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source={"location"} label={"Location of site"} />
            </SimpleForm>
        </Create>
    );
}