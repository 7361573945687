import {useGetList} from "ra-core";
import {
    Edit,
    EditProps,
    Error,
    FormDataConsumer,
    Loading, NumberInput,
    required,
    SelectInput,
    SimpleForm
} from "react-admin";
import Grid from "@mui/material/Grid";
import React from "react";
import {SanitizedGrid} from "../Common/SanitizedGrid";
import {validatePercentage, validatePositiveNumber} from "../Common/Validators";
import {NoDeleteButtonToolbar} from "../Common/EditFormToolbar";

export const ThresholdsEdit = (props:EditProps) => {
    const {
        data: locations,
        isLoading: loadingLocations,
        error: errorLocations
    } = useGetList('location', {
        pagination:{
        page: 1,
        perPage: 1000
    }, sort:{field: "", order: "ASC"}});


    if (loadingLocations) return <Loading/>;
    if (errorLocations) return <Error error={{name: "Loading error", message: "Could not load locations"}}  resetErrorBoundary={() => {return null;}}/>;

    //TODO we should extract these but the problem seems to be that the formData is not getting filled out when extract.
    //Seems that the source record is something else. Likely have to explicitly select and bind to that record but no idea how
    return (
        <Edit {...props}>
            <SimpleForm toolbar={<NoDeleteButtonToolbar/>}>
                <SelectInput disabled
                             source={"id"}
                             label={"Location"}
                             choices={Object.values(locations)}
                             validate={required()}/>
                <SanitizedGrid container spacing={2}>
                    <Grid item>
                        <SelectInput source={"totalType"}
                                     emptyValue={"NotPresent"}
                                     label={"Total"}
                                     choices={[
                                         {id: 'Above', name: 'Above'},
                                         {id: 'Below', name: 'Below'},
                                         {id: 'NotPresent', name: 'Not Present'},
                                     ]}/>
                    </Grid>
                    <FormDataConsumer subscription={{values: true}}>
                        {({formData, ...rest}) => {

                            if (formData.totalType === 'Above' || formData.totalType === 'Below') {

                                return (
                                    <Grid item><NumberInput source={"totalAmount"}
                                                            label={"Total M cells/mL"}
                                                            step={0.001}
                                                            validate={validatePositiveNumber}/></Grid>)
                            } else {
                                return null;
                            }
                        }
                        }
                    </FormDataConsumer>
                </SanitizedGrid>

                <SanitizedGrid container spacing={2}>
                    <Grid item>
                        <SelectInput source={"singleType"}
                                     emptyValue={"NotPresent"}
                                     label={"Single"}
                                     choices={[
                                         {id: 'Above', name: 'Above'},
                                         {id: 'Below', name: 'Below'},
                                         {id: 'NotPresent', name: 'Not Present'},
                                     ]}/>
                    </Grid>
                    <FormDataConsumer subscription={{values: true}}>
                        {({formData, ...rest}) => {

                            if (formData.singleType === 'Above' || formData.singleType === 'Below') {

                                return (
                                    <Grid item><NumberInput source={"singleAmount"}
                                                            label={"Amount in %"}
                                                            step={1}
                                                            validate={validatePercentage}/></Grid>)
                            } else {
                                return null;
                            }
                        }
                        }
                    </FormDataConsumer>
                </SanitizedGrid>

                <SanitizedGrid container spacing={2}>
                    <Grid item>
                        <SelectInput source={"buddingType"}
                                     emptyValue={"NotPresent"}
                                     label={"Budding"}
                                     choices={[
                                         {id: 'Above', name: 'Above'},
                                         {id: 'Below', name: 'Below'},
                                         {id: 'NotPresent', name: 'Not Present'},
                                     ]}/>
                    </Grid>
                    <FormDataConsumer subscription={{values: true}}>
                        {({formData, ...rest}) => {

                            if (formData.buddingType === 'Above' || formData.buddingType === 'Below') {

                                return (
                                    <Grid item><NumberInput source={"buddingAmount"}
                                                            label={"Amount in %"}
                                                            step={1}
                                                            validate={validatePercentage}/></Grid>)
                            } else {
                                return null;
                            }
                        }
                        }
                    </FormDataConsumer>
                </SanitizedGrid>
                <SanitizedGrid container spacing={2}>
                    <Grid item>
                        <SelectInput source={"dividingType"}
                                     emptyValue={"NotPresent"}
                                     label={"Dividing"}
                                     choices={[
                                         {id: 'Above', name: 'Above'},
                                         {id: 'Below', name: 'Below'},
                                         {id: 'NotPresent', name: 'Not Present'},
                                     ]}/>
                    </Grid>
                    <FormDataConsumer subscription={{values: true}}>
                        {({formData, ...rest}) => {

                            if (formData.dividingType === 'Above' || formData.dividingType === 'Below') {

                                return (
                                    <Grid item><NumberInput source={"dividingAmount"}
                                                            label={"Amount in %"}
                                                            step={1}
                                                            validate={validatePercentage}/></Grid>)
                            } else {
                                return null;
                            }
                        }
                        }
                    </FormDataConsumer>
                </SanitizedGrid>
                <SanitizedGrid container spacing={2}>
                    <Grid item>
                        <SelectInput source={"clumpingType"}
                                     emptyValue={"NotPresent"}
                                     label={"Clumping"}
                                     choices={[
                                         {id: 'Above', name: 'Above'},
                                         {id: 'Below', name: 'Below'},
                                         {id: 'NotPresent', name: 'Not Present'},
                                     ]}/>
                    </Grid>
                    <FormDataConsumer subscription={{values: true}}>
                        {({formData, ...rest}) => {

                            if (formData.clumpingType === 'Above' || formData.clumpingType === 'Below') {

                                return (
                                    <Grid item><NumberInput source={"clumpingAmount"}
                                                            label={"Amount in %"}
                                                            step={1}
                                                            validate={validatePercentage}/></Grid>)
                            } else {
                                return null;
                            }
                        }
                        }
                    </FormDataConsumer>
                </SanitizedGrid>
                <SanitizedGrid container spacing={2}>
                    <Grid item>
                        <SelectInput source={"viabilityType"}
                                     emptyValue={"NotPresent"}
                                     label={"Viability"}
                                     choices={[
                                         {id: 'Above', name: 'Above'},
                                         {id: 'Below', name: 'Below'},
                                         {id: 'NotPresent', name: 'Not Present'},
                                     ]}/>
                    </Grid>
                    <FormDataConsumer subscription={{values: true}}>
                        {({formData, ...rest}) => {

                            if (formData.viabilityType === 'Above' || formData.viabilityType === 'Below') {

                                return (
                                    <Grid item><NumberInput source={"viabilityAmount"}
                                                            label={"Amount in %"}
                                                            step={1}
                                                            validate={validatePercentage}/></Grid>)
                            } else {
                                return null;
                            }
                        }
                        }
                    </FormDataConsumer>
                </SanitizedGrid>
                <SanitizedGrid container spacing={2}>
                    <Grid item>
                        <SelectInput source={"elongatedRatioType"}
                                     emptyValue={"NotPresent"}
                                     label={"Elongated Alive/Total Alive cells"}
                                     choices={[
                                         {id: 'Above', name: 'Above'},
                                         {id: 'Below', name: 'Below'},
                                         {id: 'NotPresent', name: 'Not Present'},
                                     ]}/>
                    </Grid>
                    <FormDataConsumer subscription={{values: true}}>
                        {({formData, ...rest}) => {

                            if (formData.elongatedRatioType === 'Above' || formData.elongatedRatioType === 'Below') {

                                return (
                                    <Grid item><NumberInput source={"elongatedRatioAmount"}
                                                            label={"Amount in %"}
                                                            step={1}
                                                            validate={validatePercentage}/></Grid>)
                            } else {
                                return null;
                            }
                        }
                        }
                    </FormDataConsumer>
                </SanitizedGrid>
            </SimpleForm>
        </Edit>
    );
}