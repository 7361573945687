import {maxValue, minValue, number, required,} from 'react-admin';

const maxDigitsAfterDecimalPointRequired = (maxDigits:number) => (value:number, _allValues:Array<number>) => {
    if (!value) {
        return 'The number is required';
    }
    let splitted = value.toString().split(".")
    if (splitted[1] && splitted[1].length > maxDigits) {
        return `Max ${maxDigits} numbers after the decimal point expected`
    }
    return undefined;
};

const maxDigitsAfterDecimalPointOptional = (maxDigits:number) => (value:number,  _allValues:Array<number>) => {
    if (!value) {
        return undefined;
    }
    let splitted = value.toString().split(".")
    if (splitted[1] && splitted[1].length > maxDigits) {
        return `Max ${maxDigits} numbers after the decimal point expected`
    }
    return undefined;
};

const naturalNumberValidationRequired = (value:number, _allValues:Array<number>) => {
    if (!value) {
        return 'The number is required';
    }
    let splitted = value.toString().split(".")
    if (splitted[1]) {
        return 'Natural number expected'
    }
    return undefined;
};

export const validateCustomDays = [required(),
    number(),
    minValue(1, "at least 1 day"),
    maxValue(31, "Max 31 days"),
    naturalNumberValidationRequired]

export const validateDilutionFactor = [
    required(),
    number(),
    minValue(0, "This should be a positive number"),
    maxDigitsAfterDecimalPointRequired(2)];

export const validateGlucoseConcentration = [
    number(),
    minValue(0, "This should be a positive number"),
    maxDigitsAfterDecimalPointOptional(2)];

export const validatePositiveNumber = [
    required(),
    number(),
    minValue(0, "This should be a positive number"),
    maxDigitsAfterDecimalPointRequired(3)];

export const validatePercentage = [
    required(),
    number(),
    minValue(0, "Percentage cannot be lower than 0"),
    maxValue(100, "Percentage cannot be larger than 100"),
    naturalNumberValidationRequired
];
export const validatePercentageNotRequired = [
    number(),
    minValue(0, "Percentage cannot be lower than 0"),
    maxValue(100, "Percentage cannot be larger than 100"),
    maxDigitsAfterDecimalPointOptional(2)
];
