//TODO we need to get this better, this subSource thing is clearly not the correct way to do it,
// there should be a better way to get a nested property
import {useRecordContext} from "react-admin";
import React, {FC} from "react";
import {BoxWithSourceToTrickLabel} from "../Common/BoxWithSourceToTrickLabel";


export const OutOfBoundsSummaryField: FC<{ source: string, label: string, unit: string, subSource?: string }> = props => {
    const {source, unit, subSource} = props;
    const record = useRecordContext();
    const summary = record['summary']

    if (summary !== undefined) {
        const outOfBoundsKey = source + 'OutOfBounds'
        let value = summary[source];
        if (subSource) {
            value = value[subSource]
        }
        if (summary[outOfBoundsKey] === true) {
            return (
                <BoxWithSourceToTrickLabel sx={{color: 'red'}}
                                           text={value + ` ${unit}`}
                />

            )
        } else {
            return (<BoxWithSourceToTrickLabel sx={{color: 'green'}}
                                               text={value + ` ${unit}`}
            />)
        }

    } else {
        return (
            <BoxWithSourceToTrickLabel text={""}/>)

    }
}