import React from "react";
type Cell = {x:number, y:number, width:number, height:number}

export const CellsList = (props:{className:string, cellsList:[Cell]}) => {
    const {className, cellsList} = props;
    let strokeColor:string;
    switch (className) {
        case "single":
            strokeColor = "var(--color-single)";
            break;
        case "clumping":
            strokeColor = "var(--color-clumping)";
            break;
        case "dividing":
            strokeColor = "var(--color-dividing)";
            break;
        default:
            strokeColor = "var(--color-budding)";
    }

    return (<g className={className}>
        {cellsList.map((cell) => <rect
            x={cell.x}
            y={cell.y}
            width={cell.width}
            height={cell.height}
            stroke={strokeColor}
            strokeWidth="3"
            fill="none"/>)}
    </g>);
}