import {Labeled, useRecordContext} from "react-admin";
import React, {FC} from "react";

//The label will display if it has a child component that has the property source filled out
const FakeFieldToTrickLabelToDisplayAndReplaceSpan = ({ source }) => {
    return (<span>{source}</span>);
};

//The label will display if it has a child component that has the property source filled out
export const FakeFieldToTrickLabelToDisplay = ({ source }) => {
    return (<React.Fragment/>);
};

export const TimeField:FC<{source:string, source2:string, index:number}> = props => {
    const record = useRecordContext();
    const realRecord = record.dashboards[props.index];
    const {source, source2} = props;
    const time = realRecord[source];
    const days = realRecord[source2];
    if (days) {
        return (<Labeled label={"Time (in days)"} className={"dashboard-show__label-plot-name"}>
            <FakeFieldToTrickLabelToDisplayAndReplaceSpan source={days}/>
        </Labeled>);
    }
    if (time && !days && time === 'last_week') {
        return (<Labeled label={"Time"} className={"dashboard-show__label-plot-name"}>
            <FakeFieldToTrickLabelToDisplayAndReplaceSpan  source={"Since last week"}/>
        </Labeled>);
    }
    if (time && !days && time === 'last_month') {
        return (<Labeled label={"Time"} className={"dashboard-show__label-plot-name"}>
            <FakeFieldToTrickLabelToDisplayAndReplaceSpan  source={"Since last month"} />
        </Labeled>);
    }

    return (<Labeled label={"Time"} className={"dashboard-show__label-plot-name"}><FakeFieldToTrickLabelToDisplay source={"jos"}/></Labeled>);
}