import {useGetList} from "ra-core";
import {Error, Loading, NumberField, SelectField, Show, ShowProps, SimpleShowLayout} from "react-admin";
import React from "react";

export const ExperimentDefaultShow = (props:ShowProps) => {
    const {
        data: locations,
        isLoading: loadingLocations,
        error: errorLocations
    } = useGetList('location', {
        pagination:{
        page: 1,
        perPage: 1000
    }, sort:{field: "", order: "ASC"}});


    const {
        data: tanks,
        isLoading: loadingTanks,
        error: errorTanks
    } = useGetList('tank', {
        pagination:{
        page: 1,
        perPage: 1000
    }, sort : {field: "", order: "ASC"}});


    const {
        data: strains,
        isLoading: loadingStrains,
        error: errorStrains
    } = useGetList('strain', {
        pagination:{
        page: 1,
        perPage: 1000
    }, sort:{field: "", order: "ASC"}});


    if (loadingLocations) return <Loading/>;
    if (errorLocations) return <Error error={{name: "Loading error", message: "Could not load locations"}}  resetErrorBoundary={() => {return null;}}/>;

    if (loadingTanks) return <Loading/>;
    if (errorTanks) return <Error error={{name: "Loading error", message: "Could not load tanks"}}  resetErrorBoundary={() => {return null;}}/>;

    if (loadingStrains) return <Loading/>;
    if (errorStrains) return <Error error={{name: "Loading error", message: "Could not load strains"}}  resetErrorBoundary={() => {return null;}}/>;

    return (
        <Show title="ExperimentDefault detail" {...props} >
            <SimpleShowLayout>
                <SelectField source={"id"} label={"Location"} choices={Object.values(locations)}/>
                <SelectField source={"tankId"} label={"Tank"} choices={Object.values(tanks)}/>
                <SelectField source={"strainId"} label={"Strain"} choices={Object.values(strains)}/>
                <NumberField source={"dilutionFactor"} label={"Dilution factor"}
                             options={{maximumFractionDigits: 2}}/>
            </SimpleShowLayout>
        </Show>
    );
}