import * as React from "react";
import {Admin, CustomRoutes, defaultTheme, Login, Resource} from 'react-admin';

import createDecoratedDataProvider, {
    createLocalDecoratedDataProvider
} from "./RestClient/specialDataProvider";
import {createAuthProvider, localAuthProvider} from './Auth/createAuthProvider';
import {useMsal} from "@azure/msal-react";
import "./custom.css"
import {AccountInfo, IPublicClientApplication} from "@azure/msal-browser";
import {LocationList} from "./Locations/list";
import {LocationCreate} from "./Locations/create";
import {StrainList} from "./Strains/list";
import {StrainCreate} from "./Strains/create";
import {TankCreate} from "./Tanks/create";
import {TankList} from "./Tanks/list";
import {EmailConfigList} from "./EmailConfig/list";
import {EmailConfigCreate} from "./EmailConfig/create";
import {EmailConfigShow} from "./EmailConfig/show";
import {EmailConfigEdit} from "./EmailConfig/edit";
import {ThresholdsList} from "./Thresholds/list";
import {ThresholdsCreate} from "./Thresholds/create";
import {ThresholdsEdit} from "./Thresholds/edit";
import {ThresholdsShow} from "./Thresholds/show";
import {ExperimentDefaultList} from "./ExperimentDefaults/list";
import {ExperimentDefaultCreate} from "./ExperimentDefaults/create";
import {ExperimentDefaultEdit} from "./ExperimentDefaults/edit";
import {ExperimentDefaultShow} from "./ExperimentDefaults/show";
import {ExperimentsList} from "./Experiments/list";
import {ExperimentsShowFull} from "./Experiments/showFull";
import {ExperimentsCreateWithIdentity} from "./Experiments/createWithIdentity";
import {DashboardWithIdentity} from "./Dashboard/Dashboard";
import {DashboardConfigList} from "./DashboardConfig/list";
import {DashboardConfigCreate} from "./DashboardConfig/create";
import {DashboardConfigEdit} from "./DashboardConfig/edit";
import {DashboardConfigShow} from "./DashboardConfig/show";
import {Route} from "react-router-dom";
import VersionComponent from "./CustomMenu/VersionComponent";
import {CustomLayout} from "./CustomMenu/CustomLayout";
import {LocationShow} from "./Locations/show";
import {CountProportionsPlotPage} from "./Plots/CountProportions/CountProportionsPlotPage";
import {ElongatedRatiosPlotPage} from "./Plots/ElongatedRatio/ElongatedRatioPlotPage";
import {MeanAndCiPlotPage} from "./Plots/MeanAndCi/MeanAndCiPlotPage";


const authMethod = process.env.REACT_APP_AUTH_METHOD

const theme = {
    ...defaultTheme,
    sidebar: {
        width: 360, // The default value is 240
        closedWidth: 70, // The default value is 55
    },
};

function createAdminResources() {
    return [
        <Resource name="experiments" list={ExperimentsList} show={ExperimentsShowFull}
                  create={ExperimentsCreateWithIdentity}/>,
        <Resource name="location" list={LocationList} create={LocationCreate} show={LocationShow}/>,
        <Resource name="tank" list={TankList} create={TankCreate}/>,
        <Resource name="strain" list={StrainList} create={StrainCreate}/>,
        <Resource name={"thresholds"} list={ThresholdsList}
                  create={ThresholdsCreate}
                  edit={ThresholdsEdit}
                  show={ThresholdsShow}/>,
        <Resource name={"email_configs"} list={EmailConfigList}
                  create={EmailConfigCreate}
                  edit={EmailConfigEdit}
                  show={EmailConfigShow}
        />,
        <Resource name="dashboard_config" list={DashboardConfigList} create={DashboardConfigCreate}
                  show={DashboardConfigShow} edit={DashboardConfigEdit}/>,
        <Resource name={"default_configs"}/>,
        <Resource name={"experiment_pictures"}/>,
        <Resource name={"experiment_files"}/>,
        <Resource name={"experiment_defaults"} list={ExperimentDefaultList}
                  create={ExperimentDefaultCreate}
                  edit={ExperimentDefaultEdit}
                  show={ExperimentDefaultShow}
        />,
        <Resource name={"dashboard"}/>
    ]
}

function createUserResources() {
    return [
        <Resource name="experiments" list={ExperimentsList} show={ExperimentsShowFull}
                  create={ExperimentsCreateWithIdentity}/>,
        <Resource name="location" list={LocationList}/>,
        <Resource name="tank" list={TankList}/>,
        <Resource name="strain" list={StrainList}/>,

        <Resource name={"default_configs"}/>,
        <Resource name={"experiment_pictures"}/>,
        <Resource name={"dashboard"}/>
    ]
}

function createAdminAppWithMsal(msalInstance: IPublicClientApplication, accounts: AccountInfo[]) {
    return (
        <Admin
            theme={theme}
            layout={CustomLayout}
            authProvider={createAuthProvider(msalInstance, accounts)}
            dataProvider={createDecoratedDataProvider(msalInstance, accounts)}
            dashboard={DashboardWithIdentity}
        >
            <CustomRoutes>
                <Route path="/plots_count_proportions" element={<CountProportionsPlotPage/>}/>,
                <Route path="/plots_elongated_ratios" element={<ElongatedRatiosPlotPage/>}/>,
                <Route path="/plots_mean_and_ci" element={<MeanAndCiPlotPage/>}/>,
                <Route path="/version" element={<VersionComponent/>}/>,
            </CustomRoutes>
            {permissions => permissions === 'admin' ? createAdminResources() : createUserResources()}
        </Admin>
    );
}

function createAdminApp() {
    return (
        <Admin
            loginPage={<Login/>}
            theme={theme}
            layout={CustomLayout}
            authProvider={localAuthProvider}
            dataProvider={createLocalDecoratedDataProvider()}
            dashboard={DashboardWithIdentity}
        >
            <CustomRoutes>
                <Route path="/plots_count_proportions" element={<CountProportionsPlotPage/>}/>,
                <Route path="/plots_elongated_ratios" element={<ElongatedRatiosPlotPage/>}/>,
                <Route path="/plots_mean_and_ci" element={<MeanAndCiPlotPage/>}/>,
                <Route path="/version" element={<VersionComponent/>}/>,
            </CustomRoutes>
            {permissions => permissions === 'admin' ? createAdminResources() : createUserResources()}
        </Admin>
    );
}


const App = () => {
    const {instance, accounts} = useMsal();
    if (authMethod === "MSAL") {
        return createAdminAppWithMsal(instance, accounts);
    } else {
        return createAdminApp()
    }
};

export default App;

