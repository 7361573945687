import React, {Fragment} from "react";
import {useGetList} from "ra-core";
import {Datagrid, Error, List, ListProps, Loading, SelectField, TextField} from "react-admin";

const NoBulkActionButtons = (_props: {}) => (
    <Fragment>
    </Fragment>
);

export const TankList = (props: ListProps) => {
    const {
        data: locations,
        isLoading: loadingLocations,
        error: errorLocations
    } = useGetList('location', {
        pagination:{
        page: 1,
        perPage: 1000
    }, sort:{field: "", order: "ASC"}});

    if (loadingLocations) return <Loading/>;
    if (errorLocations) return (<Error error={{name: "Loading error", message: "Could not load tanks"}}  resetErrorBoundary={() => {return null;}}/>);

    return (<List {...props} bulkActionButtons={<NoBulkActionButtons/>} exporter={false}>
        <Datagrid rowClick="show">
            <TextField source="name" label={"Name"}/>
            <SelectField source={"tankType"} label={"Type of Tank"}
                         choices={[
                             {id: 'Propagator', name: 'Propagator'},
                             {id: 'Head', name: 'Head'},
                             {id: 'Tail', name: 'Tail'},
                             {id: 'Beerwell', name: 'Beerwell'},
                             {id: 'Lab', name: 'Lab'},
                             {id: 'Other', name: 'Other'},
                         ]}/>
            <SelectField source={"location"} label={"Location of Tank"}
                         choices={Object.values(locations)}/>
        </Datagrid>
    </List>);
}