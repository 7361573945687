import * as React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {Title} from 'react-admin';
import {FC} from "react";


const VersionComponent:FC = (props) => {

    return (

        <Card {...props}>
            <Title {...props} title="Version"/>
            <CardContent {...props}>
                <div>
                    <span>Version 2.0.0</span>
                </div>
            </CardContent>
        </Card>
    );

}

export default VersionComponent;
