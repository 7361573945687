import {Buffer} from "buffer";
import {
    Create, CreateProps,
    DateTimeInput,
    Error, FileField, FileInput, FormDataConsumer, ImageField, ImageInput,
    Loading, NumberInput,
    required, SelectInput,
    SimpleForm,
    TextInput,
    useGetOne, UserIdentity
} from "react-admin";
import {useGetList} from "ra-core";
import React, {useState} from "react";
import {TankInput} from "../Tanks/tankInput";
import {ExperimentsCreateActions} from "./experimentsCreateActions";

import {    validateDilutionFactor,    validateGlucoseConcentration,    validatePercentageNotRequired} from "../Common/Validators";
import {UploadFolderField} from "./uploadFolderComponent";
import {FileInputCopy} from "./uploadFolderComponentCopy";
import {DropzoneOptions} from "react-dropzone";

export const ExperimentsCreate = (props:CreateProps & {identity:UserIdentity|undefined}) => {
    const {identity} = props;
    console.log('Identity is ' +identity)
    // @ts-ignore
    const base64EncodedId = identity ? Buffer.from(identity.id).toString('base64') : Buffer.from('unknown').toString('base64');
    console.log(base64EncodedId)
    const {
        data: defaultConfig,
        isLoading: loadingDefaultConfig,
        error: errorDefaultConfig
    } = useGetOne('default_configs', {id:base64EncodedId})

    const {
        data: strains,
        isLoading: loadingStrains,
        error: errorStrains
    } = useGetList('strain', {
        pagination:{
        page: 1,
        perPage: 1000
    }, sort:{field: "", order: "ASC"}});

    const {
        data: locations,
        isLoading: loadingLocations,
        error: errorLocations
    } = useGetList('location', {
        pagination:{
        page: 1,
        perPage: 1000
    }, sort : {field: "", order: "ASC"}});

    const [currentTimeStamp] = useState(new Date());

    if (loadingStrains) return <Loading/>;
    if (errorStrains) return <Error
        error={{name: "Loading error", message: "Could not load strains"}}  resetErrorBoundary={() => {return null;}}/>;

    if (loadingLocations) return <Loading/>;
    if (errorLocations) return <Error
        error={{name: "Loading error", message: "Could not load locations"}}  resetErrorBoundary={() => {return null;}}/>;

    if (loadingDefaultConfig) return <Loading/>;
    if (errorDefaultConfig) return <Error
        error={{name: "Loading error", message: "Could not load default config"}}  resetErrorBoundary={() => {return null;}}/>;

    const opt:DropzoneOptions = {minSize:4,
        useFsAccessApi:false,
        // @ts-ignore
        webkitdirectory:"",
        // @ts-ignore
        directory:""
    }
    return (
        <Create {...props} redirect="list"  actions={<ExperimentsCreateActions/>}>
            <SimpleForm defaultValues={defaultConfig}>
                <TextInput source={"title"} label={"Name of experiment"} validate={required()}/>
                <DateTimeInput source={"creationDate"}
                               defaultValue={currentTimeStamp.toISOString()}
                               validate={required()} label={"Sample time"}/>

                <FileInput name={"files"} source={"files"} options={opt} multiple={true}
                           inputProps={{webkitdirectory:"true", directory:""}}
                           >
                    <FileField source="src" title="title" />
                </FileInput>
                <SelectInput source={"location"} choices={Object.values(locations)}
                             validate={required()}/>
                <FormDataConsumer subscription={{values: true}}>
                    {({formData, ...rest}) => {
                        if (formData.location) {

                            return <TankInput source="tank" {...rest} link={formData.location}
                                              validate={required()}/>
                        } else {
                            return null;
                        }
                    }
                    }
                </FormDataConsumer>
                <SelectInput source={"strain"} choices={Object.values(strains)}
                             validate={required()}/>
                <NumberInput source={"dilutionFactor"} label={"Dilution factor"}
                             validate={validateDilutionFactor}/>
                <NumberInput source={"ethanolPercentage"} label={"Ethanol Percentage"}
                             validate={validatePercentageNotRequired}/>
                <NumberInput source={"glucoseConcentration"} label={"Glucose (gr/L)"}
                             validate={validateGlucoseConcentration}/>
                <DateTimeInput source={"lastRepitchTime"} label={"Last Repitch Time"}/>
                <TextInput source={"comments"} label={"comments"}/>
                <TextInput source={"code"} label={"Experiment code"}/>
            </SimpleForm>
        </Create>
    );
}
