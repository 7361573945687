// in profile.js
import React, {FC, useCallback, useMemo, useState} from "react";
import {
    DateTimeInput,
    Error,
    FormDataConsumer,
    Loading,
    RaRecord,
    required,
    SaveContextProvider,
    SelectInput,
    SimpleForm,
    Title,
    useDataProvider,
    useNotify,
    useRecordContext
} from "react-admin";
import {useGetList} from "ra-core";
import {TankInput} from "../../Tanks/tankInput";

//stylesheet
import "../custom.css"

import {MeanAndCi, MeanAndCiPlotChoice} from "./MeanAndCiPlotChoice";
import {MeanAndCiPlot} from "./MeanAndCiPlot";
import {ShowMeanAndCiPlots} from "./ShowMeanAndCiPlots";
import {scryRenderedComponentsWithType} from "react-dom/test-utils";


type PartialPlotPageType = {
    save: (values: any) => void,
    now?: string,
    plotChoice: MeanAndCiPlotChoice,
    locations: RaRecord[]
};

const MeanAndCiPage: FC<PartialPlotPageType> = (props) => {
    const record = useRecordContext();
    return <SimpleForm onSubmit={props.save} record={record}>
        <DateTimeInput source={"from"}
                       validate={required()} label={"From"}/>
        <DateTimeInput source={"to"}
                       validate={required()} label={"To"}
                       defaultValue={props.now}/>
        <SelectInput source={"location"} choices={Object.values(props.locations)}
                     validate={required()}/>
        <SelectInput source={"elongated"} label={"Elongated"} choices={[
            {id: 'Elongated', name: 'Elongated'},
            {id: 'Starter', name: 'Starter'},
        ]} validate={required()}/>
        <SelectInput source={"viability"} label={"Viability"} choices={[
            {id: 'Alive', name: 'Alive'},
            {id: 'Dead', name: 'Dead'},
        ]} validate={required()}/>
      <SelectInput source={"morphology"} label={"Morphology"} choices={[
            {id: 'Single', name: 'Single'},
            {id: 'Budding', name: 'Budding'},
            {id: 'Clumping', name: 'Clumping'},
            {id: 'Dividing', name: 'Dividing'},
            {id: 'Total', name: 'Total'},
        ]} validate={required()}/>
        <FormDataConsumer subscription={{values: true}}>
            {({formData, ...rest}) => {
                if (formData.location) {
                    return <TankInput source="tank" {...rest}
                                      link={formData.location}
                                      validate={required()}/>
                } else {
                    return null;
                }
            }}
        </FormDataConsumer>
    </SimpleForm>;
}


function translateDatesToISOString(values: {
    from?: Date,
    to?: string,
}) {
    let from = values.from ? values.from.toISOString() : null;
    let to = values.to ? new Date(values.to).toISOString() : null;
    return {
        ...values,
        from: from,
        to: to,
    }
}

type PlotType = {
    dataFound: boolean,
    tankName?: string,
    locationName?: string,
    plots?: MeanAndCiPlot,
}

enum Elongated {
    Elongated ="Elongated",
    Starter = "Starter"
}

enum Viability {
    Alive = "Alive",
    Dead = "Dead"
}

enum Morphology {
    Single = "Single",
    Dividing = "Dividing",
    Clumping = "Clumping",
    Budding = "Budding",
    Total = "Total",
}

export const MeanAndCiPlotPage: FC = (props) => {
    const {
        data: locations,
        isLoading: loadingLocations,
        error: errorLocations
    } = useGetList('location', {
        pagination: {
            page: 1,
            perPage: 1000
        }, sort: {field: "", order: "ASC"}
    });

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [saving, setSaving] = useState<boolean>();
    const [plotChoice, setPlotChoice] = useState<MeanAndCiPlotChoice>({meanAndCi: null});
    const [plotValues, setPlots] = useState<PlotType>({dataFound: false})

    const handleSave = useCallback((values) => {
        setSaving(true);

        function toMeanAndCi(elongated: Elongated, viability : Viability, morphology: Morphology) {
            if(elongated && viability && morphology){
                switch (elongated) {
                    case Elongated.Elongated:
                        switch (viability) {
                            case Viability.Alive:
                                switch(morphology){
                                    case Morphology.Single:
                                        return MeanAndCi.ElongatedSingleAlive;
                                    case Morphology.Dividing:
                                        return MeanAndCi.ElongatedDividingAlive;
                                    case Morphology.Clumping:
                                        return MeanAndCi.ElongatedClumpingAlive;
                                    case Morphology.Budding:
                                        return MeanAndCi.ElongatedBuddingAlive;
                                    case Morphology.Total:
                                        return MeanAndCi.ElongatedAlive
                                }
                            case Viability.Dead:
                                switch(morphology){
                                    case Morphology.Single:
                                        return MeanAndCi.ElongatedSingleDead;
                                    case Morphology.Dividing:
                                        return MeanAndCi.ElongatedDividingDead;
                                    case Morphology.Clumping:
                                        return MeanAndCi.ElongatedClumpingDead;
                                    case Morphology.Budding:
                                        return MeanAndCi.ElongatedBuddingDead;
                                    case Morphology.Total:
                                        return MeanAndCi.ElongatedDead
                                }
                        }
                    case Elongated.Starter:
                        switch (viability) {
                            case Viability.Alive:
                                switch(morphology){
                                    case Morphology.Single:
                                        return MeanAndCi.StarterSingleAlive;
                                    case Morphology.Dividing:
                                        return MeanAndCi.StarterDividingAlive;
                                    case Morphology.Clumping:
                                        return MeanAndCi.StarterClumpingAlive;
                                    case Morphology.Budding:
                                        return MeanAndCi.StarterBuddingAlive;
                                    case Morphology.Total:
                                        return MeanAndCi.StarterAlive
                                }
                            case Viability.Dead:
                                switch(morphology){
                                    case Morphology.Single:
                                        return MeanAndCi.StarterSingleDead;
                                    case Morphology.Dividing:
                                        return MeanAndCi.StarterDividingDead;
                                    case Morphology.Clumping:
                                        return MeanAndCi.StarterClumpingDead;
                                    case Morphology.Budding:
                                        return MeanAndCi.StarterBuddingDead;
                                    case Morphology.Total:
                                        return MeanAndCi.StarterDead
                                }
                        }

                }

            } else {
                return null
            }
        }

        setPlotChoice({meanAndCi: toMeanAndCi(values.elongated, values.viability, values.morphology)})
        let retrievePlotsAsync: Promise<{ data: PlotType }> = dataProvider.retrievePlots(
            {data: {type: 'meanandci', ...translateDatesToISOString(values)}});
        retrievePlotsAsync.then(i => {
            setSaving(false);
            setPlots(i.data)
            if (i.data.dataFound) {
                notify("Plots have been retrieved");
            } else {
                notify("Plots retrieval finished, no data found");
            }
        }).catch(_f => {
            setSaving(false);
            notify(
                "A technical error occurred while retrieving plots. Please try later."
            );
        })
    }, [dataProvider, notify]);

    const saveContext = useMemo(() => ({
        save: handleSave,
        saving
    }), [saving, handleSave]);

    const [currentTimeStamp] = useState(new Date());

    if (loadingLocations) return <Loading/>;
    if (errorLocations) return <Error error={{name: "Loading error", message: "Could not load locations"}}
                                      resetErrorBoundary={() => {
                                          return null;
                                      }}/>;
    return (
        <div>
            <SaveContextProvider value={saveContext}>
                <React.Fragment>
                    <Title {...props} title="Counts with Uncertainty Interval"/>
                    <MeanAndCiPage
                        save={handleSave}
                        plotChoice={plotChoice}
                        locations={locations}
                        now={currentTimeStamp.toISOString()}/>
                </React.Fragment>
            </SaveContextProvider>

            {!saving ?
                <ShowMeanAndCiPlots tankName={plotValues.tankName}
                                    locationName={plotValues.locationName}
                                    plots={plotValues.plots}
                                    plotChoice={plotChoice}/>
                : null}
        </div>


    );
};
