import {Error, Loading, useGetOne, useRecordContext} from "react-admin";
import React, {FC} from "react";
import {CellsList} from "./cellsList";



export const SvgImage :FC= (_) => {
    const record = useRecordContext();
    let id = record.bucketId + "__" + record.pictureId;
    const {
        data: pic,
        isLoading: loadingPic,
        error: errorPic
    } = useGetOne('experiment_pictures', {id:id});


    if (loadingPic) return <Loading/>;
    if (errorPic || pic===undefined) return <Error error={{name: "Loading error", message: "Could not retrieve pictures"}}  resetErrorBoundary={() => {return null;}}/>;

    return (<svg width="1260" height="960">
        <image href={pic.content}/>
        <CellsList className={"single"} cellsList={record.single}/>
        <CellsList className={"dividing"} cellsList={record.dividing}/>
        <CellsList className={"clumping"} cellsList={record.clumping}/>
        <CellsList className={"budding"} cellsList={record.budding}/>

        Sorry, your browser does not support inline SVG.
    </svg>);
}