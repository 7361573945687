import { LogLevel } from "msal";
import {
    BrowserAuthOptions,
    BrowserSystemOptions,
    CacheOptions,
    Configuration
} from "@azure/msal-browser";

const tenantId = process.env.REACT_APP_TENANT_ID;
const clientId = process.env.REACT_APP_CLIENT_ID;
const authority = `https://login.microsoftonline.com/${tenantId}`;

const auth: BrowserAuthOptions = {
    authority: authority,
    clientId: clientId,
    postLogoutRedirectUri: window.location.origin,
    // redirectUri: redirectUri,
    navigateToLoginRequestUrl: false,
}

const cache:CacheOptions = {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
}

const system: BrowserSystemOptions =  {
    loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
                return;
            }
            switch (level) {
                case LogLevel.Error:
                    console.error(message);
                    return;
                case LogLevel.Info:
                    console.info(message);
                    return;
                case LogLevel.Verbose:
                    console.debug(message);
                    return;
                case LogLevel.Warning:
                    console.warn(message);
                    return;
                default:
                    return;
            }
        },
    },
}

export const msalConfig:Configuration = {
    auth: auth,
    cache: cache,
    system:system,
};