import {Error, Loading, useDataProvider, useRecordContext} from "react-admin"
import {get} from "react-hook-form";
import React, {FC} from "react";
import {backendUrl} from "../RestClient/specialDataProvider";
import {useGetList} from "ra-core";
import {Button} from "@mui/material";
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

type CsvDownloadProps = { identifier: string, label: string, locationIdKey: string, statusKey: string, alreadyDownloadedKey: string }

export const CsvDownload: FC<CsvDownloadProps> = (props) => {
    const {
        data: locations,
        isLoading: loadingLocations,
        error: errorLocations
    } = useGetList('location', {
        pagination: {
            page: 1,
            perPage: 1000
        }, sort: {field: "", order: "ASC"}
    });

    const record = useRecordContext();

    if (loadingLocations) return <Loading/>;
    if (errorLocations) return <Error
        error={{name: "Loading error", message: "Could not load locations"}}
        resetErrorBoundary={() => {
            return null;
        }}/>;

    const value = get(record, props.identifier);
    const locationId = get(record, props.locationIdKey);
    const status = get(record, props.statusKey);
    const alreadyDownloaded = get(record, props.alreadyDownloadedKey);
    const experimentId = value
    const locationCode = locations.find((obj) => obj.id === locationId).code
    const url = backendUrl + "/experiment_files/" + experimentId + "_" + encodeURI(locationCode)

    let className = alreadyDownloaded ? "already_downloaded" : "not_yet_downloaded";

    return (status && status.toUpperCase() === "DONE" ?
            <a href={url} download={"csv.csv"} className={className} onClick={event => {
                console.log("clicked it")
                // window.open()
            }}>Download files as csv</a>
            : null
    );
}

function createTransientLink(blob: string, downloadName: string): HTMLAnchorElement {
    const url = window.URL.createObjectURL(
        new Blob([blob]),
    );
    const link = document.createElement('a')
    link.href = url;
    link.setAttribute(
        'download',
        downloadName +".csv",
    );
    document.body.appendChild(link);
    setTimeout(function () {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }, 200);
    return link
}

export const DownloadButtonWithTransientLink: FC<{ id: string, className: string }> = (props) => {
    const dataProvider = useDataProvider();
    return (<Button
        onClick={() => {
            dataProvider.getOne("experiment_files", {id: props.id})
                .then(response => {
                    const blob = response.data.content
                    const link = createTransientLink(blob, props.id);
                    link.click();
                })
        }
        }
        aria-label="download csv">
        <DownloadRoundedIcon className={props.className}/>
    </Button>)
}

export const CsvDownloadButton: FC<CsvDownloadProps> = (props) => {
    const {
        data: locations,
        isLoading: loadingLocations,
        error: errorLocations
    } = useGetList('location', {
        pagination: {
            page: 1,
            perPage: 1000
        }, sort: {field: "", order: "ASC"}
    });


    const record = useRecordContext();

    if (loadingLocations) return <Loading/>;
    if (errorLocations) return <Error
        error={{name: "Loading error", message: "Could not load locations"}}
        resetErrorBoundary={() => {
            return null;
        }}/>;

    const value = get(record, props.identifier);
    const locationId = get(record, props.locationIdKey);
    const status = get(record, props.statusKey);
    const alreadyDownloaded = get(record, props.alreadyDownloadedKey);
    let alwaysPresentClassName = "experiments__download-icon"
    let className = alreadyDownloaded ? "already_downloaded_icon "+alwaysPresentClassName : alwaysPresentClassName
    const experimentId = value
    const locationCode = locations.find((obj) => obj.id === locationId).code
    const id = experimentId + "_" + encodeURI(locationCode)

    return (status && status.toUpperCase() === "DONE" ?
        <DownloadButtonWithTransientLink id={id} className={className}/> : null)
}