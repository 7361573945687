import {useGetList} from "ra-core";
import {
    BooleanInput,
    Datagrid,
    DateField,
    Error,
    List,
    ListProps,
    Loading,
    NumberField,
    SelectField,
    SelectInput,
    ShowButton,
    TextField
} from "react-admin";
import React from "react";
import {StatusField} from "./statusField";
import {CsvDownloadButton} from "./csvDownload";

export const ExperimentsList = (props: ListProps) => {

    const experimentFilters = [
        <BooleanInput label="Already Downloaded" source="downloaded" defaultValue={true}/>,
        <SelectInput label="Status" source="status" choices={[{id:"done", name:"Done"}, {id:"failed", name:"Failed"}, {id:"busy", name:"Busy"}]}/>,
    ];
    const {
        data: tanks,
        isLoading: loadingTanks,
        error: errorTanks
    } = useGetList('tank', {
        pagination: {
            page: 1,
            perPage: 1000
        }, sort: {field: "", order: "ASC"}
    });


    const {
        data: strains,
        isLoading: loadingStrains,
        error: errorStrains
    } = useGetList('strain', {
        pagination: {
            page: 1,
            perPage: 1000
        }, sort: {field: "", order: "ASC"}
    });


    const {
        data: locations,
        isLoading: loadingLocations,
        error: errorLocations
    } = useGetList('location', {
        pagination: {
            page: 1,
            perPage: 1000
        }, sort: {field: "", order: "ASC"}
    });

    if (loadingTanks) return <Loading/>;
    if (errorTanks) return <Error error={{name: "Loading error", message: "Could not load tanks"}}
                                  resetErrorBoundary={() => {
                                      return null;
                                  }}/>;

    if (loadingStrains) return <Loading/>;
    if (errorStrains) return <Error
        error={{name: "Loading error", message: "Could not load strains"}}
        resetErrorBoundary={() => {
            return null;
        }}/>;

    if (loadingLocations) return <Loading/>;
    if (errorLocations) return <Error
        error={{name: "Loading error", message: "Could not load locations"}}
        resetErrorBoundary={() => {
            return null;
        }}/>;

    return (
        <List className={"experiments-list"}
              sort={{field: 'creationDate.iMillis', order: 'DESC'}}
              empty={false}
              {...props}
              exporter={false}
             filters={experimentFilters}>
            <Datagrid>
                <TextField source="title" label={"Name"}/>
                <StatusField source={"status"} label={"Status"} showLabel={false}/>
                <CsvDownloadButton label={"Download"} identifier={"id"} locationIdKey={"location"} statusKey={"status"} alreadyDownloadedKey={"alreadyDownloaded"}/>
                <ShowButton/>
                <DateField source="creationDate.iMillis" label={"Sample Time"} showTime/>
                <SelectField source={"tank"} choices={Object.values(tanks)}/>
                <SelectField source={"location"} choices={Object.values(locations)}/>
                <SelectField source={"strain"} choices={Object.values(strains)}/>
                <NumberField source={"dilutionFactor"} label={"Dilution Factor"}/>
                <NumberField source={"ethanolPercentage"} label={"Ethanol Percentage"}/>
                <NumberField source={"glucoseConcentration"} label={"Glucose (gr/L)"}/>
                <DateField source={"lastRepitchTime.iMillis"} label={"Last Repitch Time"} showTime/>
                <TextField source={"comments"} label={"Comments"}/>
                <TextField source={"code"} label={"Experiment code"}/>

            </Datagrid>
        </List>
    );
}