import React, {Fragment} from "react";
import {Datagrid, List, ListProps, TextField} from "react-admin";

const NoBulkActionButtons = (_props: {}) => (
    <Fragment>
    </Fragment>
);

export const StrainList = (props: ListProps) => (
    <List {...props} bulkActionButtons={<NoBulkActionButtons/>} exporter={false}>
        <Datagrid rowClick="show">
            <TextField source="name" label={"Name"}/>
        </Datagrid>
    </List>
);
