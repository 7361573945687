import Plot from "react-plotly.js";
import React, {FC} from "react";
import * as Plotly from "plotly.js";
import {ElongatedRatioPlot} from "./ElongatedRatioPlot";
import {ElongatedRatio, ElongatedRatiosPlotChoice} from "./ElongatedRatiosPlotChoice";
import {PlotlyStylePlot} from "../CountProportions/ShowCountProportionsPlots";

export type ElongatedRatiosPlotType = {
    index: number,
    meanAndCiPlot: { mean: PlotlyStylePlot, percentile: PlotlyStylePlot },
    tankName: string,
    location: string,
    forDashboard: boolean,
    type: ElongatedRatio
}

// TODO actually we only need StarterRatio or ElongatedRatio, fix this in front and back end
enum Legend {
    StarterRatioMean = "StarterRatioMean",
    StarterRatioPercentiles = "StarterRatioPercentiles",
    ElongatedRatioMean = "ElongatedRatioMean",
    ElongatedRatioPercentiles = "ElongatedRatioPercentiles"
}

function createTitles(legend: Legend): { meanTitle: string, percentileTitle: string, graphTitle: string } {
    console.log("KASPER " + legend)
    switch (legend) {
        case Legend.StarterRatioMean:
            return {
                meanTitle: "Starter Ratio Mean",
                percentileTitle: "Starter Ratio Percentile",
                graphTitle: " Starter Ratio"
            }
            break;
        case Legend.StarterRatioPercentiles:
            return {
                meanTitle: "Starter Ratio Mean",
                percentileTitle: "Starter Ratio Percentile",
                graphTitle: " Starter Ratio"
            }
            break;
        case Legend.ElongatedRatioMean:
            return {
                meanTitle: "Elongated Ratio Mean",
                percentileTitle: "Elongated Ratio Percentile",
                graphTitle: " Elongated Ratio"
            }
            break;
        case Legend.ElongatedRatioPercentiles:
            return {
                meanTitle: "Elongated Ratio Mean",
                percentileTitle: "Elongated Ratio Percentile",
                graphTitle: " Elongated Ratio"
            }
            break;
    }
}

export const CreateElongatedRatioPlot: FC<ElongatedRatiosPlotType> = props => {
    const {index, meanAndCiPlot, tankName, location, forDashboard, type} = props;
    const typeValue = ElongatedRatio[type]
    const meanColor = getComputedStyle(document.documentElement).getPropertyValue('--color-mean-and-ci-graph');
    const percentileColor = getComputedStyle(document.documentElement).getPropertyValue('--color-mean-and-ci-graph-lighter');

    console.log("mean plot " + meanAndCiPlot.mean)
    console.log("percentile plot " + meanAndCiPlot.percentile)
    let titles = {
        meanTitle : `${typeValue} Mean`,
        percentileTitle : "Alive ${typeValue} Percentile",
        graphTitle : "Alive ${typeValue} Ratio",
    };
    // let titles = createTitles(meanAndCiPlot.mean.legend);
    const myData: Plotly.Data[] = [
        {
            type: 'scatter',
            x: meanAndCiPlot.mean.x.map(o => new Date(o.iMillis)),
            y: meanAndCiPlot.mean.y,
            mode: 'lines',
            line: {color: meanColor},
            name: titles.meanTitle
        },
        {
            type: 'scatter',
            x: (meanAndCiPlot.percentile.x).map(o => new Date(o.iMillis)),
            y: meanAndCiPlot.percentile.y,
            fill: 'toself',
            line: {color: percentileColor},
            hoverinfo: 'skip',
            name: titles.percentileTitle
        },
    ];
    return (
        <Plot
            key={index}
            className={forDashboard ? "dashboard-item" : "plot-item"}
            data={myData}
            layout={{
                autosize: true,
                title: titles.graphTitle + ' for ' + tankName + ' at ' + location,
                showlegend: true,
                barmode: 'stack',
                yaxis: {
                    title: {
                        text: 'Alive ${typeValue} Ratio'
                    }
                },
                xaxis: {
                    title: {
                        text: 'Time'
                    }
                }
            }}
            useResizeHandler
        />);
}


export type ProportionPlotType = { legend: Legend, x: Array<{ iMillis: number }>, y: Float32Array };
export type CreateDashboardPlotType = {
    index: number,
    proportionPlot: ProportionPlotType,
    tankName: string,
    location: string
}

type PlotlyPlot = { legend: string, x: Array<{ iMillis: number }>, y: Float32Array };

function choosePlot(plotChoice: ElongatedRatiosPlotChoice): (ElongatedRatioPlot) => {
    mean: PlotlyStylePlot,
    percentile: PlotlyStylePlot
} {

    // console.log("printing elongated ratio")
    // console.log(plotChoice.elongatedRatio)
    if (!plotChoice.elongatedRatio) {
        return null
    }

    switch (plotChoice.elongatedRatio) {
        case ElongatedRatio.Elongated:
            // console.log("Returning elongated lambda")
            return p => {
                return p.elongatedPlot
            }
        case ElongatedRatio.Starter:
            return p => p.starterPlot
    }

    return null
}

export const ShowElongatedRatiosPlots: FC<{
    tankName?: string,
    locationName?: string,
    plots?: ElongatedRatioPlot,
    plotChoice: ElongatedRatiosPlotChoice
}> = (props) => {

    let plotPicker = choosePlot(props.plotChoice);
    const {tankName, locationName, plots} = props;
    return <div className="plot-list">
        {plots && plotPicker(plots) ?
            <CreateElongatedRatioPlot index={0} meanAndCiPlot={plotPicker(plots)} tankName={tankName ?? "unknown tank"}
                                      location={locationName ?? "unknown location"}  type={props.plotChoice.elongatedRatio} forDashboard={false}/> : null}
    </div>;
}