import {useGetList} from "ra-core";
import {
    Datagrid,
    Error,
    List,
    ListProps,
    Loading,
    SelectField,
    useRecordContext
} from "react-admin";
import React, {FC} from "react";

const NumberOfDashboardsField: FC = _ => {
    const record = useRecordContext();
    const dashboards = record['dashboards']
    const numberOfDashboards = dashboards ? dashboards.length : 0

    return (<span>{` ${numberOfDashboards}`} </span>)

}

export const DashboardConfigList = (props: ListProps) => {
    const {
        data: locations,
        isLoading: loadingLocations,
        error: errorLocations
    } = useGetList('location', {
        pagination: {
            page: 1,
            perPage: 1000
        }, sort: {field: "", order: "ASC"}
    });

    if (loadingLocations) return <Loading/>;
    if (errorLocations) return <Error error={{name: "Loading error", message: "Could not load locations"}} resetErrorBoundary={() => {return null;}}/>;

    return (
        <List {...props}  >
            <Datagrid rowClick="show">
                <SelectField source={"id"} label={"location"} choices={Object.values(locations)}/>
                <NumberOfDashboardsField/>
            </Datagrid>
        </List>
    );

}