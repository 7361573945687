import {useGetList} from "ra-core";
import {Error, Loading, SelectInput, SelectInputProps, useRecordContext} from "react-admin";
import React from "react";

export const TankInput = (props : SelectInputProps & {link?:string} & {link_id?:string}) => {
    const record =useRecordContext();
    const link = props.link?props.link : record[props.link_id]
    const {
        data: tanks,
        isLoading: loadingTanks,
        error: errorTanks
    } = useGetList('tank', {
        pagination:{
        page: 1,
        perPage: 1000
    }, sort:{field:"", order:"ASC"}, filter: {filter: link}});

    const errorMessage = {name:"Loading error", message:"Could not load tanks"};

    if (loadingTanks) return <Loading/>;
    if (errorTanks) return <Error error={errorMessage}  resetErrorBoundary={() => {return null;}}/>;
    return (
            <SelectInput choices={Object.values(tanks)}
                         {...props} />
    );

}