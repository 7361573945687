import Plot from "react-plotly.js";
import React, {FC} from "react";
import * as Plotly from "plotly.js";
import {PlotlyStylePlot} from "./CountProportions/ShowCountProportionsPlots";

export type CiPlotType = {
    mean: { x: Array<{ iMillis: number }>, y: Float32Array },
    percentileCombo: { x: Array<{ iMillis: number }>, y: Float32Array },
    yAxisLegend: string,
    plotName: { type: string, value: string }
};
export type MeanAndCiPlotType = {
    mean: PlotlyStylePlot,
    percentile : PlotlyStylePlot
}
export type CreateMeanAndCiPlotType = {index:number, p:MeanAndCiPlotType, tankName:string, locationName:string, forDashboard:boolean}

export const CreateMeanAndCiPlot:FC<CreateMeanAndCiPlotType> = props => {
    const {index, p, tankName, locationName, forDashboard} = props;
    const color = getComputedStyle(document.documentElement).getPropertyValue('--color-mean-and-ci-graph');
    const percentileColor = getComputedStyle(document.documentElement).getPropertyValue('--color-mean-and-ci-graph-lighter');
    // const plotText = p.plotName.type + ' ' + p.plotName.value
    const plotText = "TODO"
    let myData:Plotly.Data[]  = [
        {
            type: 'scatter',
            x: p.mean.x.map(o => new Date(o.iMillis)),
            y: p.mean.y,
            mode: 'lines',
            line: {color: color},
            name: 'mean'
        },
        {
            type: 'scatter',
            x: (p.percentile.x).map(o => new Date(o.iMillis)),
            y: p.percentile.y,
            fill: 'toself',
            line: {color: percentileColor},
            hoverinfo: 'skip',
            name: 'Percentile 25 - 75'
        },
    ];
    return (<Plot
        key={index}
        className={forDashboard ? "dashboard-item" : "plot-item"}
        data={myData}
        layout={{
            title: plotText + ' for ' + tankName + ' at ' + locationName,
            xaxis: {
                title: {
                    text: 'Time'
                }
            },
            yaxis: {
                title: {
                    text: 'TODO'
                }
            }
        }}
        useResizeHandler
    />)
}
