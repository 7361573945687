import Plot from "react-plotly.js";
import React, {FC} from "react";
import * as Plotly from "plotly.js";
import {Elongatedness, CountProportionsPlotChoice, Viability} from "./CountProportionsPlotChoice";
import {CountPlot} from "./CountPlot";

export type CountProportionsPlotType = {
    index: number, daSubPlots: Array<PlotlyStylePlot>, tankName: string, location: string, forDashboard: boolean
}

enum Legend {
    Single = 1,
    Budding = 2,
    Dividing = 3,
    Clumping = 4
}

//Needs to be single element, so we can get the tank and location name off it
export const CreateNormalPlot: FC<CountProportionsPlotType> = props => {
    const {index, daSubPlots, tankName, location, forDashboard} = props;
    console.log("KASPER " + daSubPlots);
    const colors = [
        getComputedStyle(document.documentElement).getPropertyValue('--color-for-plot-1'),
        getComputedStyle(document.documentElement).getPropertyValue('--color-for-plot-5'),
        getComputedStyle(document.documentElement).getPropertyValue('--color-for-plot-4'),
        getComputedStyle(document.documentElement).getPropertyValue('--color-for-plot-3')
    ]

    const myData: Plotly.Data[] = daSubPlots.sort((l, r) => {
        let left = Legend[l.legend];
        let right = Legend[r.legend];
        return Number(left) - Number(right)
    }).map((proportionPlot, plotIndex) => {
        return {
            type: 'bar',
            x: proportionPlot.x.map(o => new Date(o.iMillis)),
            y: proportionPlot.y,
            mode: 'lines+markers',
            marker: {
                color: colors[plotIndex]
            },
            name: proportionPlot.legend.toString()
        }
    })
    return (
        <Plot
            key={index}
            className={forDashboard ? "dashboard-item" : "plot-item"}
            data={myData}
            layout={{
                autosize: true,
                title: 'Count Proportions for ' + tankName + ' at ' + location,
                showlegend: true,
                barmode: 'stack',
                yaxis: {
                    title: {
                        text: 'Cell Type Proportions'
                    }
                },
                xaxis: {
                    title: {
                        text: 'Time'
                    }
                }
            }}
            useResizeHandler
        />);
}

function decideColor(legend: Legend) {
    const singleColor = getComputedStyle(document.documentElement).getPropertyValue('--color-for-plot-1')
    const buddingColor = getComputedStyle(document.documentElement).getPropertyValue('--color-for-plot-5')
    const dividingColor = getComputedStyle(document.documentElement).getPropertyValue('--color-for-plot-4')
    const clumpingColor = getComputedStyle(document.documentElement).getPropertyValue('--color-for-plot-3')
    const totalColor = getComputedStyle(document.documentElement).getPropertyValue('--color-for-plot-2')

    if (legend === Legend.Single) {
        return singleColor
    }

    if (legend === Legend.Budding) {
        return buddingColor
    }

    if (legend === Legend.Dividing) {
        return dividingColor
    }

    if (legend === Legend.Clumping) {
        return clumpingColor
    }
    return totalColor
}


export type PlotlyStylePlot = { legend: Legend, x: Array<{ iMillis: number }>, y: Float32Array };
export type CreateDashboardPlotType = {
    index: number,
    proportionPlot: PlotlyStylePlot,
    tankName: string,
    location: string
}
//TODO This is just different enough to warrant a split. Annoying because the rest is not split. Can we make this better?
export const CreateDashboardPlot: FC<CreateDashboardPlotType> = props => {

    const {index, proportionPlot, tankName, location} = props;
    const color = decideColor(proportionPlot.legend)

    let xValues = proportionPlot.x.map(o => new Date(o.iMillis));
    const myData: Plotly.Data[] =
        [{
            type: 'bar',
            x: xValues,
            y: proportionPlot.y,
            mode: 'lines+markers',
            marker: {
                color: color
            },
            name: proportionPlot.legend.toString()
        }]

    return (
        <Plot
            key={index}
            className="dashboard-item"
            data={myData}
            layout={{
                autosize: true,
                title: 'Count Proportions for ' + tankName + ' at ' + location,
                showlegend: true,
                barmode: 'stack',
                yaxis: {
                    title: {
                        text: 'Cell Type Proportions'
                    }
                },
                xaxis: {
                    title: {
                        text: 'Time'
                    }
                }
            }}
            useResizeHandler
        />);
}


type PlotlyPlot = { legend: string, x: Array<{ iMillis: number }>, y: Float32Array };

function choosePlot(plotChoice: CountProportionsPlotChoice): (CountPlot) => Array<PlotlyStylePlot> {

    if (!plotChoice.elongatedness || !plotChoice.viability) {
        return () => new Array<PlotlyStylePlot>()
    }

    switch (plotChoice.elongatedness) {
        case Elongatedness.Elongated:
            switch (plotChoice.viability) {
                case Viability.Alive:
                    return p => p.elongatedAlivePlot
                case Viability.Dead:
                    return p => p.elongatedDeadPlot
            }
            break
        case Elongatedness.Starter:
            switch (plotChoice.viability) {
                case Viability.Alive:
                    return p => p.starterAlivePlot
                case Viability.Dead:
                    return p => p.starterDeadPlot
            }
    }

    return () => new Array<PlotlyStylePlot>()
}

export const ShowCountProportionsPlots: FC<{
    tankName?: string,
    locationName?: string,
    plots?: CountPlot,
    plotChoice: CountProportionsPlotChoice
}> = (props) => {

    let plotPicker = choosePlot(props.plotChoice);
    const {tankName, locationName, plots} = props;
    return <div className="plot-list">
        {plots && plotPicker(plots) && plotPicker(plots).length > 1 ?
            <CreateNormalPlot index={0} daSubPlots={plotPicker(plots)} tankName={tankName ?? "unknown tank"}
                              location={locationName ?? "unknown location"} forDashboard={false}/> : null}
    </div>;
}