import React from 'react';
import {createRoot} from "react-dom/client";
import './index.css';
import App from './App';
// @ts-ignore
import reportWebVitals from './reportWebVitals';
import {MsalAuthenticationTemplate, MsalProvider} from "@azure/msal-react";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./Auth/msalAuthProvider";

const authMethod = process.env.REACT_APP_AUTH_METHOD

console.log("Auth method "+authMethod);



const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

if(authMethod === "MSAL") {
    console.log("Rendering MSAL")
    const msalInstance = new PublicClientApplication(msalConfig);
    root.render(<React.StrictMode>
            <MsalProvider instance={msalInstance}>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                    <App/>
                </MsalAuthenticationTemplate>
            </MsalProvider>
        </React.StrictMode>
    );
} else {
    console.log("NOT Rendering MSAL")
    root.render(<React.StrictMode><App/></React.StrictMode>)
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
