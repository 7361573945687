import {useGetList} from "ra-core";
import {Datagrid, EmailField, Error, List, ListProps, Loading, SelectField, TextInput} from "react-admin";
import React from "react";

const emailConfigFilters: JSX.Element[] = [
    <TextInput label="Search" source="emailAddressIsLike" alwaysOn />,
];

export const EmailConfigList = (props:ListProps) => {
    const {
        data: locations,
        isLoading: loadingLocations,
        error: errorLocations
    } = useGetList('location', {
        pagination:{
        page: 1,
        perPage: 1000
    }, sort:{field: "", order: "ASC"}});

    if (loadingLocations) return <Loading/>;
    if (errorLocations) return <Error error={{name: "Loading error", message: "Could not load locations"}}  resetErrorBoundary={() => {return null;}}/>;

    return (
        <List {...props} exporter={false} filters={emailConfigFilters} sort={{ field: 'emailAddress', order: 'ASC' }}>
            <Datagrid rowClick="edit">
                <EmailField source={"emailAddress"} label={"Email"}/>
                <SelectField source={"locationId"} label={"Location"} choices={Object.values(locations)}/>
            </Datagrid>
        </List>
    );
}