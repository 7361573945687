import * as React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {DashboardComponent, Error, Loading, Title, useGetIdentity, useGetOne} from 'react-admin';
import Plot from "react-plotly.js";
import {Buffer} from "buffer";
import {CreateNormalPlot, PlotlyStylePlot} from "../Plots/CountProportions/ShowCountProportionsPlots";
import {CreateMeanAndCiPlot, MeanAndCiPlotType} from "../Plots/ShowMeanAndCIPlots";
import {CreateElongatedRatioPlot} from "../Plots/ElongatedRatio/ShowElongatedRatiosPlots";
import {ElongatedRatio} from "../Plots/ElongatedRatio/ElongatedRatiosPlotChoice";

type DashboardType = {
    meanAndCiPlot?: MeanAndCiPlotType,
    countPlot: Array<PlotlyStylePlot>,
    elongatedRatioPlot: MeanAndCiPlotType,
    dashboardType: string,
    tankName: string,
    locationName: string,
    emptyText: string
}

export const DashboardWithIdentity: DashboardComponent = props => {
    const {identity, isLoading, error} = useGetIdentity();
    if (isLoading) {
        return <Loading/>
    }
    if (error) {
        return <Error error={{name: "Loading error", message: "Could not load identity"}} resetErrorBoundary={() => {
            return null;
        }}/>
    }
    return <Dashboard {...props} identity={identity}/>
}
//TODO this any-type should be here until we can solve the problem that referencing to the component as
//  ComponentType<WithPermissionsChildrenParams&{identity:UserIdentity}> goes away;
export const Dashboard = (props: any) => {
    const {identity} = props;
    console.log('Identity is ' + identity)
    // @ts-ignore
    const base64EncodedId = identity ? Buffer.from(identity.id).toString('base64') : Buffer.from('unknown').toString('base64');
    const {
        data: dashboardConfig,
        isLoading: loadingDashboard,
        error: errorDashboard
    } = useGetOne('dashboard', {id: base64EncodedId});


    if (loadingDashboard) return <Loading/>;
    if (errorDashboard) return <Error error={{name: "Loading error", message: "Could not load dashboards"}}
                                      resetErrorBoundary={() => {
                                          return null;
                                      }}/>;
    if (!dashboardConfig || !dashboardConfig.dashboards || dashboardConfig.dashboards.length <= 0) {
        return (<Card>
            <Title title="Welcome to the dashboard"/>
            <CardContent>
                <span>No dashboard configured for this location, or no location attached to your user</span>
            </CardContent>
        </Card>);
    }


    return (<Card>
        <Title title="Welcome to the dashboard"/>
        <CardContent>
            <div className={"dashboard__plot-list"}>
                {dashboardConfig.dashboards.map((dashboard: DashboardType, index: number) => showPlot(dashboard, index))}
            </div>
        </CardContent>
    </Card>);
}

function showPlot(dashboard: DashboardType, index: number) {
    if (dashboard.dashboardType === 'MeanAndCiPlot') {
        return <CreateMeanAndCiPlot index={index} p={dashboard.meanAndCiPlot} tankName={dashboard.tankName}
                                    locationName={dashboard.locationName} forDashboard={true}/>
    }
    if (dashboard.dashboardType === 'CountProportionPlot') {
        return <CreateNormalPlot index={index} daSubPlots={dashboard.countPlot} tankName={dashboard.tankName}
                                 location={dashboard.locationName} forDashboard={true}/>
    }
    if (dashboard.dashboardType === 'ElongatedRatioPlot') {
        return <CreateElongatedRatioPlot index={index} meanAndCiPlot={dashboard.elongatedRatioPlot}
                                         tankName={dashboard.tankName}
                                         location={dashboard.locationName} type={ElongatedRatio.Starter}
                                         forDashboard={true}/>
    }
    return (
        <Plot
            key={index}
            className={"dashboard-item"}
            data={[
                {
                    x: [],
                    y: [],
                },
                {type: 'bar', x: [], y: []},
            ]}
            layout={{title: 'A placeholder for an empty plot for ' + dashboard.emptyText}}
            useResizeHandler
        />
    );
}