import {Show, ShowProps, SimpleShowLayout, TextField} from "react-admin";
import React from "react";

export const LocationShow = (props: ShowProps) => {

    return (
        <Show title="Location detail" {...props} >
            <SimpleShowLayout>
                <TextField source="name" label={"Name"}/>
            </SimpleShowLayout>
        </Show>
    );
}