// https://stackoverflow.com/questions/53278585/custom-layout-in-simpleform-component-on-react-admin


import Grid, {GridSpacing} from "@mui/material/Grid";
import React, {FC} from "react";

export const SanitizedGrid:FC<{spacing?:GridSpacing, container:boolean}> = (props) => {
    const {spacing, container} = props;
    return (
        <Grid {...props} spacing={spacing} container={container}/>
    );
};