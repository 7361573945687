import React, {FC} from "react";
import {useRecordContext} from "react-admin";



function showByCssClass(classname:string) {
    [].forEach.call(document.querySelectorAll('.' + classname), function (el) {
        // @ts-ignore
        el.classList.toggle('hidden');
    });
}

export const PngActions:FC = (_) => {
    const record = useRecordContext();
    if (record && record.status === 'BUSY') {
        return <div>Awaiting completion of experiments, no cells recognized yet</div>
    } else {
        return (
            <div className={"button-menu"}>
                <label className={"toggle-switch-single"}>
                    <input type={"checkbox"}
                           className="toggle-switch-single__input chart-button--single"
                           onClick={() => showByCssClass('single')}
                           defaultChecked={true}/>
                    <span className="toggle-switch-single__slider"></span>
                    <span className="toggle-switch-single__label">Single</span>
                </label>
                <label className={"toggle-switch-dividing"}>
                    <input type={"checkbox"}
                           className="toggle-switch-dividing__input chart-button--dividing"
                           onClick={() => showByCssClass('dividing')}
                           defaultChecked={true}/>
                    <span className="toggle-switch-dividing__slider"></span>
                    <span className="toggle-switch-dividing__label">Dividing</span>
                </label>

                <label className={"toggle-switch-clumping"}>
                    <input type={"checkbox"}
                           className="toggle-switch-clumping__input chart-button--clumping"
                           onClick={() => showByCssClass('clumping')}
                           defaultChecked={true}/>
                    <span className="toggle-switch-clumping__slider"></span>
                    <span className="toggle-switch-clumping__label">Clumping</span>
                </label>

                <label className={"toggle-switch-budding"}>
                    <input type={"checkbox"}
                           className="toggle-switch-budding__input chart-button--budding"
                           onClick={() => showByCssClass('budding')}
                           defaultChecked={true}/>
                    <span className="toggle-switch-budding__slider"></span>
                    <span className="toggle-switch-budding__label">Budding</span>
                </label>

            </div>

        );
    }
}
