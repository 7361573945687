import {useGetList} from "ra-core";
import {EmailField, Error, Loading, SelectField, Show, ShowProps, SimpleShowLayout} from "react-admin";
import React from "react";

export const EmailConfigShow = (props:ShowProps) => {
    const {
        data: locations,
        isLoading: loadingLocations,
        error: errorLocations
    } = useGetList('location', {
        pagination:{
        page: 1,
        perPage: 1000
    }, sort:{field: "", order: "ASC"}});

    if (loadingLocations) return <Loading/>;
    if (errorLocations) return <Error error={{name: "Loading error", message: "Could not load locations"}}  resetErrorBoundary={() => {return null;}}/>;

    return (
        <Show title="Email config detail" {...props} >
            <SimpleShowLayout>
                <EmailField source={"emailAddress"} label={"Email"}/>
                <SelectField source={"locationId"} label={"Location"} choices={Object.values(locations)}/>
            </SimpleShowLayout>
        </Show>
    );
}