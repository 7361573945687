import React, {FC, useEffect} from "react";
import {Error, Labeled, NumberInput, required, SelectInput} from "react-admin";
import {TankInput} from "../Tanks/tankInput";
import {ConfigType} from "./configType";
import {validateCustomDays} from "../Common/Validators";
import {elongatedChoices, morphologyChoices, viabilityChoices} from "./choices";

export const DashboardMeanAndCiConfig: FC<ConfigType> = props => {
    const {getSource, timeChoices, formData, scopedFormData, setDisableAdd, disableAdd} = props
    useEffect(() => {
            if (formData && formData.plots && formData.plots.length >= 4) {
                setDisableAdd(true)
            } else {
                setDisableAdd(false)
            }
        },
        [disableAdd, formData, setDisableAdd]);
    if (!getSource) {
        return <Error error={{name: "Unexpected exception", message: "getSource function passed in is undefined"}}
                      resetErrorBoundary={() => {
                          return null;
                      }}/>
    }
    return (<React.Fragment>
        <div className={"plot-handle__wrapper"}>
            <Labeled label={"Visualize"} className={"plot-handle__label-parent"}><span/></Labeled>
            <SelectInput source={getSource("time")} choices={timeChoices}
                         label={"Choose time type"} validate={required()}/>
            {scopedFormData.time && scopedFormData.time === "custom" ?
                <NumberInput source={getSource("days")}
                             label={"Custom number of days"}
                             step={1}
                             validate={validateCustomDays}/> : null}
            {formData.id ? <TankInput source={getSource("tank")}
                                      label={"tank"}
                                      link={formData.id}
                                      validate={required()}/> : null}
            <SelectInput source={getSource("elongated")} choices={elongatedChoices}
                         label={"Elongated"} validate={required()}/>
            <SelectInput source={getSource("viability")} choices={viabilityChoices}
                         label={"Viability"} validate={required()}/>
            <SelectInput source={getSource("morphology")} choices={morphologyChoices}
                         label={"Morphology"} validate={required()}/>
        </div>

    </React.Fragment>)
}