import {CreateProps, Error, Loading, useGetIdentity} from "react-admin";
import React from "react";
import {ExperimentsCreate} from "./create";

export const ExperimentsCreateWithIdentity = (props:CreateProps) => {
    const {identity, isLoading, error} = useGetIdentity();
    if (isLoading) {
        return <Loading/>
    }
    if (error) {
        return <Error error={{name: "Loading error", message: "Could not load identity"}}  resetErrorBoundary={() => {return null;}}/>
    }
    console.log('identity no longer loading, here goes : ')
    console.log(identity)
    return <ExperimentsCreate {...props} identity={identity}/>
}