import {useGetList} from "ra-core";
import {
    Edit,
    EditProps,
    email,
    Error,
    Loading,
    required,
    SelectInput,
    SimpleForm,
    TextInput
} from "react-admin";
import React from "react";
import {NoDeleteButtonToolbar} from "../Common/EditFormToolbar";


const validateEmail = [required(), email()]


export const EmailConfigEdit = (props: EditProps) => {
    const {
        data: locations,
        isLoading: loadingLocations,
        error: errorLocations
    } = useGetList('location', {
        pagination: {
            page: 1,
            perPage: 1000
        }, sort: {field: "", order: "ASC"}
    });


    if (loadingLocations) return <Loading/>;
    if (errorLocations) return <Error
        error={{name: "Loading error", message: "Could not load locations"}}
        resetErrorBoundary={() => {
            return null;
        }}/>;

    return (
        <Edit {...props} >
            <SimpleForm toolbar={<NoDeleteButtonToolbar/>}>
                <SelectInput source={"locationId"}
                             label={"Location"}
                             choices={Object.values(locations)}
                             validate={required()}/>
                <TextInput source={"emailAddress"} label={"Email address"}
                           validate={validateEmail}/>
            </SimpleForm>
        </Edit>
    );
}