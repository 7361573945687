import {useGetList} from "ra-core";
import {
    Create,
    CreateProps,
    email,
    Error,
    Loading,
    required,
    SelectInput,
    SimpleForm,
    TextInput
} from "react-admin";
import React from "react";

const validateEmail = [required(),email()]

export const EmailConfigCreate = (props:CreateProps) => {
    const {
        data: locations,
        isLoading: loadingLocations,
        error: errorLocations
    } = useGetList('location', {
        pagination:{
        page: 1,
        perPage: 1000
    }, sort :{field: "", order: "ASC"}});


    if (loadingLocations) return <Loading/>;
    if (errorLocations) return <Error error={{name: "Loading error", message: "Could not load locations"}}  resetErrorBoundary={() => {return null;}}/>;


    return (
        <Create {...props} redirect="list" >
            <SimpleForm  >
                <SelectInput source={"locationId"}
                             label={"Location"}
                             choices={Object.values(locations)}
                             validate={required()}/>
                <TextInput source={"emailAddress"} label={"Email address"} validate={validateEmail}/>
            </SimpleForm>
        </Create>
    );
}