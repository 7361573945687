export enum Elongatedness {
    Starter = "Starter",
    Elongated = "Elongated"
}

export enum Viability {
    Alive = "Alive",
    Dead = "Dead"
}

export type CountProportionsPlotChoice = { elongatedness?: Elongatedness, viability?: Viability };