import {useGetList} from "ra-core";
import {
    Edit,
    EditProps,
    Error,
    Loading,
    NumberInput,
    required,
    SelectInput,
    SimpleForm
} from "react-admin";
import {TankInput} from "../Tanks/tankInput";
import React from "react";

import {NoDeleteButtonToolbar} from "../Common/EditFormToolbar";
import {validateDilutionFactor} from "../Common/Validators";

export const ExperimentDefaultEdit = (props: EditProps) => {
    const {
        data: locations,
        isLoading: loadingLocations,
        error: errorLocations
    } = useGetList('location', {
        pagination: {
            page: 1,
            perPage: 1000
        }, sort: {field: "", order: "ASC"}
    });


    const {
        data: strains,
        isLoading: loadingStrains,
        error: errorStrains
    } = useGetList('strain', {
        pagination: {
            page: 1,
            perPage: 1000
        }, sort: {field: "", order: "ASC"}
    });

    if (loadingLocations) return <Loading/>;
    if (errorLocations) return <Error
        error={{name: "Loading error", message: "Could not load locations"}}
        resetErrorBoundary={() => {
            return null;
        }}/>;


    if (loadingStrains) return <Loading/>;
    if (errorStrains) return <Error
        error={{name: "Loading error", message: "Could not load strains"}}
        resetErrorBoundary={() => {
            return null;
        }}/>;



    return (
        <Edit {...props} >
            <SimpleForm toolbar={<NoDeleteButtonToolbar/>}>
                <SelectInput disabled
                             source={"id"}
                             label={"Location"}
                             choices={Object.values(locations)}
                             validate={required()}/>
                <TankInput source="tankId"
                           link_id={"id"}
                           validate={required()}/>
                <SelectInput
                    source={"strainId"}
                    label={"Strain"}
                    choices={Object.values(strains)}
                    validate={required()}/>
                <NumberInput source={"dilutionFactor"} label={"Dilution factor"}
                             step={0.01}
                             validate={validateDilutionFactor}/>

            </SimpleForm>
        </Edit>
    );
}