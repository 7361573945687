import {useGetList} from "ra-core";
import {
    Create,
    CreateProps,
    Error,
    FormDataConsumer,
    Loading, NumberInput,
    required,
    SelectInput,
    SimpleForm
} from "react-admin";
import {TankInput} from "../Tanks/tankInput";
import React from "react";

import {validateDilutionFactor} from "../Common/Validators";

export const ExperimentDefaultCreate = (props:CreateProps) => {
    const {
        data: locations,
        isLoading: loadingLocations,
        error: errorLocations
    } = useGetList('location', {
        pagination:{
        page: 1,
        perPage: 1000
    }, sort:{field: "", order: "ASC"}, filter: {filter: 'no_experiment_defaults'}});

    const {
        data: strains,
        isLoading: loadingStrains,
        error: errorStrains
    } = useGetList('strain', {
        pagination:{
        page: 1,
        perPage: 1000
    }, sort: {field: "", order: "ASC"}});


    if (loadingLocations) return <Loading/>;
    if (errorLocations) return <Error error={{name: "Loading error", message: "Could not load locations"}}  resetErrorBoundary={() => {return null;}}/>;


    if (loadingStrains) return <Loading/>;
    if (errorStrains) return <Error error={{name: "Loading error", message: "Could not load strains"}}  resetErrorBoundary={() => {return null;}}/>;


    return (
        <Create {...props}  redirect="list" >
            <SimpleForm>
                <SelectInput source={"id"}
                             label={"Location"}
                             choices={Object.values(locations)}
                             validate={required()}/>

                <FormDataConsumer subscription={{values: true}}>
                    {({formData, ...rest}) => {
                        if (formData.id) {
                            return <TankInput source="tankId" {...rest} link={formData.id}
                                              validate={required()}/>
                        } else {
                            return null;
                        }
                    }
                    }
                </FormDataConsumer>
                <SelectInput source={"strainId"}
                             label={"Strain"}
                             choices={Object.values(strains)}
                             validate={required()}/>
                <NumberInput source={"dilutionFactor"} label={"Dilution factor"}
                             step={0.01}
                             validate={validateDilutionFactor}/>

            </SimpleForm>
        </Create>
    );
}