import React, {FC} from "react";
import {TextField, useRecordContext} from "react-admin";
import {BoxWithSourceToTrickLabel} from "../Common/BoxWithSourceToTrickLabel";

export const StatusField: FC<{ source: string, showLabel: boolean, label: string }> = props => {
    const record = useRecordContext();
    const {source, showLabel, label} = props;
    const status = record[source]
    if (status === 'DONE') {
        if (showLabel) {
            return (<BoxWithSourceToTrickLabel sx={{color: 'green'}}
                                               text={status}/>)
        } else {
            // @ts-ignore
            return <TextField sx={{color: 'green'}}
                              {...props} />;

        }
    }
    if (status === 'FAILED') {
        if (showLabel) {
            return (<BoxWithSourceToTrickLabel sx={{color: 'red'}}
                                               text={status}/>)
        }
        // @ts-ignore
        return <TextField
            sx={{color: 'red'}}
            {...props} />;
    }
    // @ts-ignore
    return <TextField {...props}/>;
}

