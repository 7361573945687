import Plot from "react-plotly.js";
import React, {FC} from "react";
import * as Plotly from "plotly.js";
import {MeanAndCi, MeanAndCiPlotChoice} from "./MeanAndCiPlotChoice";
import {MeanAndCiPlot} from "./MeanAndCiPlot";

export type MeanAndCiPlotType = {
    index: number,
    meanAndCiPlot: { mean: ProportionPlotType, percentile: ProportionPlotType },
    tankName: string,
    location: string,
    forDashboard: boolean
}

enum Legend {
    ElongatedBuddingAlive = "ElongatedBuddingAlive",
    ElongatedBuddingDead = "ElongatedBuddingDead",
    ElongatedSingleAlive = "ElongatedSingleAlive",
    ElongatedSingleDead = "ElongatedSingleDead",
    ElongatedClumpingAlive = "ElongatedClumpingAlive",
    ElongatedClumpingDead = "ElongatedClumpingDead",
    ElongatedDividingAlive = "ElongatedDividingAlive",
    ElongatedDividingDead = "ElongatedDividingDead",
    StarterBuddingAlive = "StarterBuddingAlive",
    StarterBuddingDead = "StarterBuddingDead",
    StarterSingleAlive = "StarterSingleAlive",
    StarterSingleDead = "StarterSingleDead",
    StarterClumpingAlive = "StarterClumpingAlive",
    StarterClumpingDead = "StarterClumpingDead",
    StarterDividingAlive = "StarterDividingAlive",
    StarterDividingDead = "StarterDividingDead",
    ElongatedAlive = "ElongatedAlive",
    ElongatedDead = "ElongatedDead",
    StarterAlive = "StarterAlive",
    StarterDead = "StarterDead",
}

function createTitles(legend: Legend): { meanTitle: string, percentileTitle: string, graphTitle: string } {
    console.log("KASPER " + legend)
    switch (legend) {
        case Legend.ElongatedBuddingAlive:
            return {
                meanTitle: "Elongated Budding Alive Mean",
                percentileTitle: "Elongated Budding Alive Percentile",
                graphTitle: "Elongated Budding Alive"
            }
        case Legend.ElongatedBuddingDead:
            return {
                meanTitle: "Elongated Budding Dead Mean",
                percentileTitle: "Elongated Budding Dead Percentile",
                graphTitle: "Elongated Budding Dead"
            }
        case Legend.ElongatedSingleAlive:
            return {
                meanTitle: "Elongated Single Alive Mean",
                percentileTitle: "Elongated Single Alive Percentile",
                graphTitle: "Elongated Single Alive"
            }
        case Legend.ElongatedSingleDead:
            return {
                meanTitle: "Elongated Single Dead Mean",
                percentileTitle: "Elongated Single Dead Percentile",
                graphTitle: "Elongated Single Dead"
            }
        case Legend.ElongatedClumpingAlive:
            return {
                meanTitle: "Elongated Clumping Alive Mean",
                percentileTitle: "Elongated Clumping Alive Percentile",
                graphTitle: "Elongated Clumping Alive"
            }
        case Legend.ElongatedClumpingDead:
            return {
                meanTitle: "Elongated Clumping Dead Mean",
                percentileTitle: "Elongated Clumping Dead Percentile",
                graphTitle: "Elongated Clumping Dead"
            }
        case Legend.ElongatedDividingAlive:
            return {
                meanTitle: "Elongated Dividing Alive Mean",
                percentileTitle: "Elongated Dividing Alive Percentile",
                graphTitle: "Elongated Dividing Alive"
            }
        case Legend.ElongatedDividingDead:
            return {
                meanTitle: "Elongated Dividing Dead Mean",
                percentileTitle: "Elongated Dividing Dead Percentile",
                graphTitle: "Elongated Dividing Dead"
            }
        case Legend.StarterBuddingAlive:
            return {
                meanTitle: "Starter Budding Alive Mean",
                percentileTitle: "Starter Budding Alive Percentile",
                graphTitle: "Starter Budding Alive"
            }
        case Legend.StarterBuddingDead:
            return {
                meanTitle: "Starter Budding Dead Mean",
                percentileTitle: "Starter Budding Dead Percentile",
                graphTitle: "Starter Budding Dead"
            }
        case Legend.StarterSingleAlive:
            return {
                meanTitle: "Starter Single Alive Mean",
                percentileTitle: "Starter Single Alive Percentile",
                graphTitle: "Starter Single Alive"
            }
        case Legend.StarterSingleDead:
            return {
                meanTitle: "Starter Single Dead Mean",
                percentileTitle: "Starter Single Dead Percentile",
                graphTitle: "Starter Single Dead"
            }
        case Legend.StarterClumpingAlive:
            return {
                meanTitle: "Starter Clumping Alive Mean",
                percentileTitle: "Starter Clumping Alive Percentile",
                graphTitle: "Starter Clumping Alive"
            }
        case Legend.StarterClumpingDead:
            return {
                meanTitle: "Starter Clumping Dead Mean",
                percentileTitle: "Starter Clumping Dead Percentile",
                graphTitle: "Starter Clumping Dead"
            }
        case Legend.StarterDividingAlive:
            return {
                meanTitle: "Starter Dividing Alive Mean",
                percentileTitle: "Starter Dividing Alive Percentile",
                graphTitle: "Starter Dividing Alive"
            }
        case Legend.StarterDividingDead:
            return {
                meanTitle: "Starter Dividing Dead Mean",
                percentileTitle: "Starter Dividing Dead Percentile",
                graphTitle: "Starter Dividing Dead"
            }
        case Legend.ElongatedAlive:
            return {
                meanTitle: "Elongated Alive Mean",
                percentileTitle: "Elongated Alive Percentile",
                graphTitle: "Elongated Alive"
            }
        case Legend.ElongatedDead:
            return {
                meanTitle: "Elongated Dead Mean",
                percentileTitle: "Elongated Dead Percentile",
                graphTitle: "Elongated Dead"
            }
        case Legend.StarterAlive:
            return {
                meanTitle: "Starter Alive Mean",
                percentileTitle: "Starter Alive Percentile",
                graphTitle: "Starter Alive"
            }
        case Legend.StarterDead:
            return {
                meanTitle: "Starter Dead Mean",
                percentileTitle: "Starter Dead Percentile",
                graphTitle: "Starter Dead"
            }

    }
}

export const CreateNormalPlot: FC<MeanAndCiPlotType> = props => {
    const {index, meanAndCiPlot, tankName, location, forDashboard} = props;
    const meanColor = getComputedStyle(document.documentElement).getPropertyValue('--color-mean-and-ci-graph');
    const percentileColor = getComputedStyle(document.documentElement).getPropertyValue('--color-mean-and-ci-graph-lighter');

    console.log("mean plot " + meanAndCiPlot.mean)
    console.log("percentile plot " + meanAndCiPlot.percentile)
    let titles = createTitles(meanAndCiPlot.mean.legend);
    const myData: Plotly.Data[] = [
        {
            type: 'scatter',
            x: meanAndCiPlot.mean.x.map(o => new Date(o.iMillis)),
            y: meanAndCiPlot.mean.y,
            mode: 'lines',
            line: {color: meanColor},
            name: titles.meanTitle
        },
        {
            type: 'scatter',
            x: (meanAndCiPlot.percentile.x).map(o => new Date(o.iMillis)),
            y: meanAndCiPlot.percentile.y,
            fill: 'toself',
            line: {color: percentileColor},
            hoverinfo: 'skip',
            name: titles.percentileTitle
        },
    ];
    return (
        <Plot
            key={index}
            className={forDashboard ? "dashboard-item" : "plot-item"}
            data={myData}
            layout={{
                autosize: true,
                title: titles.graphTitle + ' for ' + tankName + ' at ' + location,
                showlegend: true,
                barmode: 'stack',
                yaxis: {
                    title: {
                        text: 'Yeast cells per mL'
                    }
                },
                xaxis: {
                    title: {
                        text: 'Time'
                    }
                }
            }}
            useResizeHandler
        />);
}


export type ProportionPlotType = { legend: Legend, x: Array<{ iMillis: number }>, y: Float32Array };
export type CreateDashboardPlotType = {
    index: number,
    proportionPlot: ProportionPlotType,
    tankName: string,
    location: string
}
//TODO This is just different enough to warrant a split. Annoying because the rest is not split. Can we make this better?
// export const CreateDashboardPlot: FC<CreateDashboardPlotType> = props => {
//
//     const {index, proportionPlot, tankName, location} = props;
//     const color = decideColor(proportionPlot.legend)
//
//     let xValues = proportionPlot.x.map(o => new Date(o.iMillis));
//     const myData: Plotly.Data[] =
//         [{
//             type: 'scatter',
//             x: xValues,
//             y: proportionPlot.y,
//             mode: 'lines',
//             marker: {
//                 color: color
//             },
//             name: "mean"
//         }]
//
//     return (
//         <Plot
//             key={index}
//             className="dashboard-item"
//             data={myData}
//             layout={{
//                 autosize: true,
//                 title: 'Count Proportions for ' + tankName + ' at ' + location,
//                 showlegend: true,
//                 barmode: 'stack',
//                 yaxis: {
//                     title: {
//                         text: 'Cell Type Proportions'
//                     }
//                 },
//                 xaxis: {
//                     title: {
//                         text: 'Time'
//                     }
//                 }
//             }}
//             useResizeHandler
//         />);
// }


type PlotlyPlot = { legend: string, x: Array<{ iMillis: number }>, y: Float32Array };

function choosePlot(plotChoice: MeanAndCiPlotChoice): (MeanAndCiPlot) => {
    mean: ProportionPlotType,
    percentile: ProportionPlotType
} {

    if (!plotChoice.meanAndCi) {
        return null
    }

    switch (plotChoice.meanAndCi) {
        case MeanAndCi.ElongatedBuddingAlive:
            return p => p.elongatedBuddingAlivePlot
        case MeanAndCi.ElongatedBuddingDead:
            return p => p.elongatedBuddingDeadPlot
        case MeanAndCi.ElongatedSingleAlive:
            return p => p.elongatedSingleAlivePlot
        case MeanAndCi.ElongatedSingleDead:
            return p => p.elongatedSingleDeadPlot
        case MeanAndCi.ElongatedClumpingAlive:
            return p => p.elongatedClumpingAlivePlot
        case MeanAndCi.ElongatedClumpingDead:
            return p => p.elongatedClumpingDeadPlot
        case MeanAndCi.ElongatedDividingAlive:
            return p => p.elongatedDividingAlivePlot
        case MeanAndCi.ElongatedDividingDead:
            return p => p.elongatedDividingDeadPlot
        case MeanAndCi.StarterBuddingAlive:
            return p => p.starterBuddingAlivePlot
        case MeanAndCi.StarterBuddingDead:
            return p => p.starterBuddingDeadPlot
        case MeanAndCi.StarterSingleAlive:
            return p => p.starterSingleAlivePlot
        case MeanAndCi.StarterSingleDead:
            return p => p.starterSingleDeadPlot
        case MeanAndCi.StarterClumpingAlive:
            return p => p.starterClumpingAlivePlot
        case MeanAndCi.StarterClumpingDead:
            return p => p.starterClumpingDeadPlot
        case MeanAndCi.StarterDividingAlive:
            return p => p.starterDividingAlivePlot
        case MeanAndCi.StarterDividingDead:
            return p => p.starterDividingDeadPlot
        case MeanAndCi.ElongatedAlive:
            return p => p.elongatedAlivePlot
        case MeanAndCi.ElongatedDead:
            return p => p.elongatedDeadPlot
        case MeanAndCi.StarterAlive:
            return p => p.starterAlivePlot
        case MeanAndCi.StarterDead:
            return p => p.starterDeadPlot

    }

    return null
}

export const ShowMeanAndCiPlots: FC<{
    tankName?: string,
    locationName?: string,
    plots?: MeanAndCiPlot,
    plotChoice: MeanAndCiPlotChoice
}> = (props) => {
    console.log("Showing elongated ratios plots " + props.plots)

    let plotPicker = choosePlot(props.plotChoice);
    const {tankName, locationName, plots} = props;
    return <div className="plot-list">
        {plots && plotPicker(plots) ?
            <CreateNormalPlot index={0} meanAndCiPlot={plotPicker(plots)} tankName={tankName ?? "unknown tank"}
                              location={locationName ?? "unknown location"} forDashboard={false}/> : null}
    </div>;
}