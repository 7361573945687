import {useGetList} from "ra-core";
import React, {useState} from "react";
import {
    ArrayInput,
    Edit, EditProps,
    Error, FormDataConsumer,
    Loading,
    required,
    SelectInput,
    SimpleForm,
    SimpleFormIterator
} from "react-admin";
import {DashboardCountConfig} from "./countConfig";
import {DashboardMeanAndCiConfig} from "./meanAndCiConfig";
import {DashboardElongatedRatioConfig} from "./elongatedRatioConfig";


export const DashboardConfigEdit = (props:EditProps) => {

    const {
        data: locations,
        isLoading: loadingLocations,
        error: errorLocations
    } = useGetList('location', {pagination: {
            page: 1,
            perPage: 1000
        },sort: {field: "", order: "ASC"}, filter: {filter: 'no_dashboard'}});

    const [disableAdd, setDisableAdd] = useState(false);

    if (loadingLocations) return <Loading/>;
    if (errorLocations) return <Error error={{name: "Loading error", message: "Could not load locations"}} resetErrorBoundary={() => {return null;}}/>;

    let plotChoices = [
        {id: 'countproportions', name: 'Count Proportions'},
        {id: 'meanandci', name: 'Mean and CI'},
        {id: 'elongatedratio', name: 'Elongated Alive Ratio'},
    ];

    let timeChoices = [
        {id: 'last_week', name: 'Last Week'},
        {id: 'last_month', name: 'Last Month'},
        {id: 'custom', name: 'Custom nbr of days'},
    ];

    //Area and count are apparently always the same


    return (<Edit {...props}>
        <SimpleForm>
            <SelectInput source={"id"}
                         label={"Location"}
                         choices={Object.values(locations)}
                         validate={required()}/>

            <ArrayInput source="dashboards" validate={[required()]}>
                <SimpleFormIterator disableAdd={disableAdd}>
                    <SelectInput source="plotType" choices={plotChoices} validate={required()}
                                 label={"Choose plot type"}/>

                    <FormDataConsumer subscription={{values: true}}>
                        {({
                              formData, // The whole form data
                              scopedFormData, // The data for this item of the ArrayInput
                              getSource, // A function to get the valid source inside an ArrayInput
                              ...rest
                          }) => {
                            if (scopedFormData && scopedFormData.plotType === 'countproportions') {
                                return <DashboardCountConfig getSource={getSource}
                                                             timeChoices={timeChoices}
                                                             formData={formData}
                                                             scopedFormData={scopedFormData}
                                                             disableAdd={disableAdd}
                                                             setDisableAdd={setDisableAdd}/>;
                            }

                            if (scopedFormData && scopedFormData.plotType === 'meanandci') {
                                return <DashboardMeanAndCiConfig getSource={getSource}
                                                                 timeChoices={timeChoices}
                                                                 formData={formData}
                                                                 scopedFormData={scopedFormData}
                                                                 disableAdd={disableAdd}
                                                                 setDisableAdd={setDisableAdd}/>;
                            }

                            if (scopedFormData && scopedFormData.plotType === 'elongatedratio') {
                                return <DashboardElongatedRatioConfig getSource={getSource}
                                                                 timeChoices={timeChoices}
                                                                 formData={formData}
                                                                 scopedFormData={scopedFormData}
                                                                 disableAdd={disableAdd}
                                                                 setDisableAdd={setDisableAdd}/>;
                            }
                        }}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>);
}

