import {useGetList} from "ra-core";
import {
    ArrayField,
    Datagrid,
    DateField,
    Error,
    Loading,
    NumberField,
    SelectField,
    Show,
    ShowProps,
    TabbedShowLayout,
    TextField
} from "react-admin";
import React from "react";
import {StatusField} from "./statusField";
import {ConditionalFailureField} from "./conditionalFailureField";
import {ExperimentsCreateActions} from "./experimentsCreateActions";
import {OutOfBoundsSummaryField} from "./outOfBoundsSummaryField";
import {PngActions} from "./pngActions";
import {SvgImage} from "./svgImage";
import {Box} from "@mui/material";
import {SimpleSummaryField} from "./simpleSummaryField";

export const ExperimentsShowFull = (props: ShowProps) => {
    const {
        data: tanks,
        isLoading: loadingTanks,
        error: errorTanks
    } = useGetList('tank', {
        pagination: {
            page: 1,
            perPage: 1000
        }, sort: {field: "", order: "ASC"}
    });


    const {
        data: strains,
        isLoading: loadingStrains,
        error: errorStrains
    } = useGetList('strain', {
        pagination: {
            page: 1,
            perPage: 1000
        }, sort: {field: "", order: "ASC"}
    });


    const {
        data: locations,
        isLoading: loadingLocations,
        error: errorLocations
    } = useGetList('location', {
        pagination: {
            page: 1,
            perPage: 1000
        }, sort: {field: "", order: "ASC"}
    });

    if (loadingTanks) return <Loading/>;
    if (errorTanks) return <Error error={{name: "Loading error", message: "Could not load tanks"}}
                                  resetErrorBoundary={() => {
                                      return null;
                                  }}/>;

    if (loadingStrains) return <Loading/>;
    if (errorStrains) return <Error
        error={{name: "Loading error", message: "Could not load strains"}}
        resetErrorBoundary={() => {
            return null;
        }}/>;

    if (loadingLocations) return <Loading/>;
    if (errorLocations) return <Error
        error={{name: "Loading error", message: "Could not load locations"}}
        resetErrorBoundary={() => {
            return null;
        }}/>;

    return (
        <Show title="Experiments Full Detail" {...props} actions={<ExperimentsCreateActions/>}>
            <TabbedShowLayout syncWithLocation={false}>
                <TabbedShowLayout.Tab label={"Metadata"}>
                    <TextField source="title" label={"Name"}/>
                    <DateField source="creationDate.iMillis" label={"Sample Time"} showTime/>

                    <SelectField source={"tank"} choices={Object.values(tanks)}/>
                    <SelectField source={"location"} choices={Object.values(locations)}/>
                    <SelectField source={"strain"} choices={Object.values(strains)}/>
                    <NumberField source={"dilutionFactor"} label={"Dilution factor"}
                                 options={{maximumFractionDigits: 2}}/>
                    <NumberField source={"ethanolPercentage"} label={"Ethanol Percentage"}
                                 options={{maximumFractionDigits: 2}}/>
                    <NumberField source={"glucoseConcentration"}
                                 label={"Glucose (gr/L)"}
                                 options={{maximumFractionDigits: 2}}/>
                    <DateField source={"lastRepitchTime.iMillis"} label={"Last Repitch Time"}
                               showTime/>
                    <TextField source={"comments"} label={"Comments"}/>
                    <TextField source={"code"} label={"Experiment Code"}/>
                    <StatusField source={"status"} label={"Status"} showLabel={true}/>
                    <ConditionalFailureField/>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label={"Summary"}>
                    <OutOfBoundsSummaryField source={"total"} label={"Total"} unit={"M cells/mL"}
                                             subSource={"value"}/>
                    <OutOfBoundsSummaryField source={"viability"} label={"Viability (Alive cells / total cells)"} unit={"%"}/>
                    <OutOfBoundsSummaryField source={"elongatedAliveCells"} label={"Elongated Alive cells/ Total Alive cells"}
                                             unit={"%"}/>
                    <SimpleSummaryField source={"totalStarterAliveCellCount"} label={"Starter Alive Cell count"}
                                        unit={"M cells/mL"}  subSource={"value"}/>
                    <OutOfBoundsSummaryField source={"singleRatio"} label={"Single Ratio"} unit={"%"}/>
                    <OutOfBoundsSummaryField source={"buddingRatio"} label={"Budding Ratio"} unit={"%"}/>
                    <OutOfBoundsSummaryField source={"dividingRatio"} label={"Dividing Ratio"} unit={"%"}/>
                    <OutOfBoundsSummaryField source={"clumpingRatio"} label={"Clumping Ratio"} unit={"%"}/>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label={"Pictures"}>
                    <PngActions/>
                    <ArrayField label={"A-Series"} source={"aSeries"}>
                        <Datagrid>
                            <SvgImage/>
                        </Datagrid>
                    </ArrayField>
                    <ArrayField label={"B-Series"} source={"bSeries"}>
                        <Datagrid>
                            <SvgImage/>
                        </Datagrid>
                    </ArrayField>
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>
        </Show>
    )
        ;
}