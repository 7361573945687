import {TextField, useRecordContext} from "react-admin";
import React, {FC} from "react";

export const ConditionalFailureField :FC= _ => {
    const record = useRecordContext();
    const status = record['status']

    if (status === 'FAILED') {
        return <TextField source={"failureReason"} label={"Failure Reason"} />;
    }
    return null;
}