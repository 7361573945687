import {useGetList} from "ra-core";
import {Create, CreateProps, Error, Loading, SelectInput, SimpleForm, TextInput} from "react-admin";
import React from "react";

export const TankCreate = (props: CreateProps) => {
    const {
        data: locations,
        isLoading: loadingLocations,
        error: errorLocations
    } = useGetList('location', {
        pagination:{
        page: 1,
        perPage: 1000
    }, sort:{field: "", order: "ASC"}});

    if (loadingLocations) return <Loading/>;
    if (errorLocations) return (
        <Error error={{name: "Loading error", message: "Could not load locations"}}  resetErrorBoundary={() => {return null;}}/>);


    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source={"tank"} label={"Name of tank"}/>
                <SelectInput source={"tankType"} label={"Type of Tank"}
                             choices={[
                                 {id: 'Propagator', name: 'Propagator'},
                                 {id: 'Head', name: 'Head'},
                                 {id: 'Tail', name: 'Tail'},
                                 {id: 'Beerwell', name: 'Beerwell'},
                                 {id: 'Lab', name: 'Lab'},
                                 {id: 'Other', name: 'Other'},
                             ]}/>
                <SelectInput source={"location"} label={"Location of Tank"}
                             choices={Object.values(locations)}/>
            </SimpleForm>
        </Create>
    );
}