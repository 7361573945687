import {Layout, Menu, usePermissions} from 'react-admin';
import * as React from 'react';
import BarChartIcon from '@mui/icons-material/BarChart';
import LabelIcon from '@mui/icons-material/Label';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ViewList from '@mui/icons-material/ViewList'


const ConfigurationMenu = () => {
    const {permissions} = usePermissions();
    return (<Menu
            // sidebarIsOpen={true}
        >
            {/*<Menu.DashboardItem/>*/}
            <Menu.ResourceItem name={"experiments"}/>
            <Menu.ResourceItem name={"location"}/>
            <Menu.ResourceItem name={"tank"}/>
            <Menu.ResourceItem name={"strain"}/>
            {permissions === 'admin' ?
                <Menu.Item to={"/thresholds"} primaryText={"Configure thresholds"}
                           leftIcon={<ViewList/>}/> : null
            }
            {permissions === 'admin' ?
                <Menu.Item to={"/email_configs"} primaryText={"Configure locations of users"}
                           leftIcon={<ViewList/>}/> : null
            }
            {permissions === 'admin' ?
                <Menu.Item to={"/experiment_defaults"}
                           primaryText={"Configure defaults for experiments"}
                           leftIcon={<ViewList/>}/> : null
            }
            {permissions === 'admin' ?
                <Menu.Item to={"/dashboard_config"} primaryText={"Configure dashboard"}
                           leftIcon={<ViewList/>}/> : null
            }
            <Menu.Item
                to="/plots_mean_and_ci"
                primaryText="Plot Mean And Ci"
                leftIcon={<BarChartIcon/>}
            />
            <Menu.Item
                to="/plots_count_proportions"
                primaryText="Plot Count Proportions"
                leftIcon={<BarChartIcon/>}
            />
            <Menu.Item
                to="/plots_elongated_ratios"
                primaryText="Plot Elongated Ratios"
                leftIcon={<BarChartIcon/>}
            />

            <Menu.Item
                to="/"
                primaryText="Dashboard"
                leftIcon={<DashboardIcon/>}
            />
            <Menu.Item
                to="/version"
                primaryText="Version"
                leftIcon={<LabelIcon/>}
            />
        </Menu>
    );
}


export const CustomLayout = props => <Layout {...props} menu={ConfigurationMenu}/>;


