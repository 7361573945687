export const morphologyChoices: Array<{ id: string, name: string }> = [
    {id: 'Single', name: 'Single'},
    {id: 'Budding', name: 'Budding'},
    {id: 'Dividing', name: 'Dividing'},
    {id: 'Clumping', name: 'Clumping'},
    {id: 'Total', name: 'Total'},
];


export const elongatedChoices: Array<{ id: string, name: string }> = [
    {id: 'Starter', name: 'Starter'},
    {id: 'Elongated', name: 'Elongated'},
];

export const elongatedRatioChoices: Array<{ id: string, name: string }> = [
    {id: 'StarterAlive', name: 'Starter Alive'},
    {id: 'ElongatedAlive', name: 'Elongated Alive'},
];
export const viabilityChoices: Array<{ id: string, name: string }> = [
    {id: 'Alive', name: 'Alive'},
    {id: 'Dead', name: 'Dead'},
];