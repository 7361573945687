import {Create, CreateProps, SimpleForm, TextInput} from "react-admin";
import React from "react";

export const StrainCreate = (props: CreateProps) => {

    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source={"strain"} label={"Name of strain"}/>
            </SimpleForm>
        </Create>
    );
}
