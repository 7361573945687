export enum MeanAndCi {
    ElongatedBuddingAlive = "ElongatedBuddingAlive",
    ElongatedBuddingDead = "ElongatedBuddingDead",
    ElongatedSingleAlive = "ElongatedSingleAlive",
    ElongatedSingleDead = "ElongatedSingleDead",
    ElongatedClumpingAlive = "ElongatedClumpingAlive",
    ElongatedClumpingDead = "ElongatedClumpingDead",
    ElongatedDividingAlive = "ElongatedDividingAlive",
    ElongatedDividingDead = "ElongatedDividingDead",
    StarterBuddingAlive = "StarterBuddingAlive",
    StarterBuddingDead = "StarterBuddingDead",
    StarterSingleAlive = "StarterSingleAlive",
    StarterSingleDead = "StarterSingleDead",
    StarterClumpingAlive = "StarterClumpingAlive",
    StarterClumpingDead = "StarterClumpingDead",
    StarterDividingAlive = "StarterDividingAlive",
    StarterDividingDead = "StarterDividingDead",
    ElongatedAlive = "ElongatedAlive",
    ElongatedDead = "ElongatedDead",
    StarterAlive = "StarterAlive",
    StarterDead = "StarterDead",
}

export type MeanAndCiPlotChoice = { meanAndCi?: MeanAndCi};