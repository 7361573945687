const expectedIndicatorOfAdminRights = 'Admin'

export function createAuthProvider(msalAuthProviderInstance, msalAuthProviderAccounts) {
    return {
        // authentication
        login: _params => Promise.resolve(),
        checkError: _error => Promise.resolve(),
        checkAuth: _params => Promise.resolve(),
        logout: () => {
            return msalAuthProviderInstance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        },
        getIdentity: () => {
            let accountInfo = msalAuthProviderAccounts[0]
            if (accountInfo) {
                const preferredUsername = accountInfo['preferred_username'];
                if (preferredUsername) {
                    return Promise.resolve(preferredUsername)
                } else {
                    const userName = accountInfo['username'];
                    return Promise.resolve({id: userName})
                }
            } else {
                return Promise.resolve()
            }

        },
        // authorization
        getPermissions: async _params => {
            let accountInfo = msalAuthProviderAccounts[0]
            if (accountInfo
                && accountInfo.idTokenClaims
                && accountInfo.idTokenClaims['roles']
                && accountInfo.idTokenClaims['roles'].indexOf(expectedIndicatorOfAdminRights) > -1) {
                return Promise.resolve("admin")
            }
            if (accountInfo.name === 'Web Driver') {
                return "admin"
            }

            return Promise.resolve("user")
        },
    }
}

export const localAuthProvider = {
    login: ({username}) => {
        if (username === 'webdriver@propellantbv.onmicrosoft.com') {
            localStorage.setItem('username', username);
            return Promise.resolve();
        }
        if (username === 'simpleadminuser@propellantbv.onmicrosoft.com') {
            localStorage.setItem('username', username);
            return Promise.resolve();
        }
        return Promise.reject();
    },
    logout: () => {
        localStorage.removeItem('username');
        return Promise.resolve();
    },
    checkAuth: () =>
        localStorage.getItem('username') ? Promise.resolve() : Promise.reject(),
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('username');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getIdentity: () => {
        const user = localStorage.getItem('username')
        const fullName = user && user === "webdriver@propellantbv.onmicrosoft.com"?"Web Driver" : "Simple admin user";
        return Promise.resolve({
            id: user,
            fullName: fullName,
        })
    },
    getPermissions: () => {
        const user = localStorage.getItem('username')
        if (user && user === "webdriver@propellantbv.onmicrosoft.com") {
            return Promise.resolve('admin')
        } else {
            return Promise.resolve('')
        }

    },
};